import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
	Elements,
	PaymentElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import "../Styles/EditSubscription.css";
import { db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const stripePromise = loadStripe(
	"pk_test_51PXl42HauYUkGvLIWrz4gnqR7oracH3obc9hMDRBJSAXicLJh3kA4aSmg3lNNBpWULW90TZxBFVBm3yYcygLACF900lWxt1JxG"
);

const EditSubscription = ({ user, onClose, switchChecked }) => {
	const [accountType, setAccountType] = useState("");
	const [customAmount, setCustomAmount] = useState(5);
	const [clientSecret, setClientSecret] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	useEffect(() => {
		const fetchAccountType = async () => {
			try {
				const userDoc = await getDoc(doc(db, "users", user.uid));
				if (userDoc.exists()) {
					const userData = userDoc.data();
					setAccountType(userData.accountType);
				}
			} catch (error) {
				console.error("Error fetching user data: ", error);
			}
		};

		fetchAccountType();
	}, [user.uid]);

	const handleCustomAmountChange = (event) => {
		let value = parseFloat(event.target.value);
		if (isNaN(value)) {
			value = "";
		} else if (value < 5) {
			value = 5;
		} else if (value > 100) {
			value = 100;
		}
		setCustomAmount(value);
	};

	const handleCancelSubscription = async () => {
		setLoading(true);

		// Fetch user data to get the subscription ID
		const userDoc = await getDoc(doc(db, "users", user.uid));
		if (!userDoc.exists()) {
			alert("User not found");
			setLoading(false);
			return;
		}
		const userData = userDoc.data();
		const subscriptionId = userData.subscriptionId;

		if (!subscriptionId) {
			alert("No subscription ID found for this user");
			setLoading(false);
			return;
		}

		console.log("Canceling subscription with ID:", subscriptionId); // Add this line for debugging

		try {
			const response = await fetch(
				"https://us-central1-spinflow-13626.cloudfunctions.net/cancelSubscription",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ subscriptionId }),
				}
			);

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(`Error: ${errorData.error}`);
			}

			// Call the function to delete user and team members, including storage data
			await fetch(
				"https://us-central1-spinflow-13626.cloudfunctions.net/deleteUserAndTeamMembers",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ userId: user.uid }),
				}
			);

			alert("Subscription canceled and user deleted successfully!");
			window.location.reload();
		} catch (error) {
			console.error("Error canceling subscription:", error);
			alert(`An error occurred: ${error.message}. Please try again.`);
		} finally {
			setLoading(false);
		}
	};

	const handleAddFunds = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				"https://us-central1-spinflow-13626.cloudfunctions.net/addFundsToPayAsYouGo",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ userId: user.uid, customAmount }),
				}
			);

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(`Error: ${errorData.error}`);
			}

			const { client_secret, tokensToAdd } = await response.json();
			setClientSecret(client_secret);
			// Update local user tokens
			await db
				.collection("users")
				.doc(user.uid)
				.update({
					totalTokens: db.FieldValue.increment(tokensToAdd),
				});
		} catch (error) {
			console.error("Error adding funds:", error);
			alert(`An error occurred: ${error.message}. Please try again.`);
			setLoading(false);
		}
	};

	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);

		if (!stripe || !elements) {
			return;
		}

		const { error: stripeError } = await stripe.confirmPayment({
			elements,
			confirmParams: {},
			redirect: "if_required",
		});

		if (stripeError) {
			setError(stripeError.message);
			setLoading(false);
			return;
		}

		try {
			const tokensToAdd = customAmount / 0.00983333;
			await db
				.collection("users")
				.doc(user.uid)
				.update({
					totalTokens: db.FieldValue.increment(tokensToAdd),
				});

			alert("Funds added successfully!");
			onClose();
		} catch (firestoreError) {
			setError(firestoreError.message);
			setLoading(false);
		}
	};

	return (
		<div
			className={`main-content edit-subscription main-color-${switchChecked}`}
		>
			<div className="white-box-overlay team-members-container">
				<h2>Edit Subscription</h2>
				{accountType !== "pay-as-you-go" && (
					<div className="danger-zone">
						<h3>
							<FontAwesomeIcon icon={faTriangleExclamation} /> Danger Zone{" "}
							<FontAwesomeIcon icon={faTriangleExclamation} />
						</h3>
						<p>
							<strong>Important Notice:</strong>
							<br />
							By canceling your subscription, all associated user data will be
							permanently deleted and will not be recoverable. In the event of a
							team's account cancellation, all user accounts, knowledge bases,
							workflows, and threads associated with the team will also be
							permanently deleted. This deletion process will occur{" "}
							<strong>immediately</strong>. We recommend performing this close
							to the end of the payment cycle.
						</p>
						<p>
							<strong>Disclaimer:</strong>
							<br />
							By proceeding with the cancellation, you acknowledge and accept
							that SpinFlow is not liable for any data loss or associated
							consequences arising from this action. Please ensure you have
							securely backed up any important information prior to canceling
							your subscription.
						</p>
						<button
							onClick={handleCancelSubscription}
							disabled={loading}
							className="main-content-button"
						>
							Cancel Subscription
						</button>
					</div>
				)}
				{accountType === "pay-as-you-go" && (
					<>
						<h3>Add Funds to Pay-As-You-Go Account</h3>
						<div className="custom-input">
							$
							<input
								type="number"
								min="5"
								max="100"
								value={customAmount}
								className="payment-input"
								onChange={handleCustomAmountChange}
								placeholder="Amount"
							/>
						</div>
						<button onClick={handleAddFunds} disabled={loading}>
							Add Funds
						</button>
					</>
				)}
				{clientSecret && accountType === "pay-as-you-go" && (
					<Elements stripe={stripePromise} options={{ clientSecret }}>
						<form onSubmit={handleSubmit}>
							<PaymentElement />
							{error && <div className="error">{error}</div>}
							<button type="submit" disabled={!stripe || loading}>
								{loading ? "Processing..." : "Pay"}
							</button>
						</form>
					</Elements>
				)}
			</div>
		</div>
	);
};

export default EditSubscription;
