import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
	collection,
	getDocs,
	doc,
	getDoc,
	query,
	where,
	updateDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";

const PersonalDepartments = ({ user, switchChecked }) => {
	const [personalAssistants, setPersonalAssistants] = useState([]);
	const [personalFlows, setPersonalFlows] = useState([]);
	const [teamId, setTeamId] = useState("");
	const [loading, setLoading] = useState(true);
	const [departments, setDepartments] = useState([]);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);
				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					setTeamId(userData.teamId);
				}
			} catch (error) {
				console.error("Error fetching user data: ", error);
			}
		};

		fetchUserData();
	}, [user.uid]);

	useEffect(() => {
		const fetchPersonalResources = async () => {
			if (!teamId) return;

			setLoading(true);
			try {
				// Fetch personal assistants
				const personalAssistantsSnapshot = await getDocs(
					query(
						collection(db, `teams/${teamId}/assistants`),
						where("isPersonal", "==", true),
						where("createdBy", "==", user.uid)
					)
				);
				const assistants = personalAssistantsSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setPersonalAssistants(assistants);

				// Fetch personal flows
				const personalFlowsSnapshot = await getDocs(
					query(
						collection(db, `teams/${teamId}/flows`),
						where("isPersonal", "==", true),
						where("createdBy", "==", user.uid)
					)
				);
				const flows = personalFlowsSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setPersonalFlows(flows);

				// Fetch departments
				const departmentsSnapshot = await getDocs(
					collection(db, `teams/${teamId}/departments`)
				);
				const departmentsList = departmentsSnapshot.docs.map((doc) => ({
					id: doc.id,
					name: doc.data().name,
				}));
				setDepartments(departmentsList);

				setLoading(false);
			} catch (error) {
				console.error("Error fetching personal resources: ", error);
				setLoading(false);
			}
		};

		fetchPersonalResources();
	}, [teamId, user.uid]);

	const handleShare = async (resource, resourceType, departmentId) => {
		try {
			const resourceRef = doc(
				db,
				`teams/${teamId}/${resourceType}s`,
				resource.id
			);
			await updateDoc(resourceRef, {
				isPersonal: false,
				departments: [departmentId],
			});
			alert(`${resourceType} shared successfully!`);
			// Refresh the list of personal resources
			const updatedResources =
				resourceType === "assistant"
					? personalAssistants.filter((a) => a.id !== resource.id)
					: personalFlows.filter((f) => f.id !== resource.id);
			resourceType === "assistant"
				? setPersonalAssistants(updatedResources)
				: setPersonalFlows(updatedResources);
		} catch (error) {
			console.error(`Error sharing ${resourceType}:`, error);
			alert(`Error sharing ${resourceType}. Please try again.`);
		}
	};

	if (loading) {
		return (
			<div
				className={`main-content template-content main-color-${switchChecked}`}
			>
				Loading...
			</div>
		);
	}

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<h2>My Personal Resources</h2>
			<div className={`main-rows main-rows-${switchChecked}`}>
				<div className="section">
					<h3>Personal Agents</h3>
					<div className="cards">
						{personalAssistants.map((assistant) => (
							<div
								key={assistant.id}
								className={`card card-color-${switchChecked} card-border`}
								style={{ borderTop: "2px solid transparent" }}
							>
								<div className="ttl">{assistant.name.toUpperCase()}</div>
								<p>{assistant.type}</p>
								<Link
									to={`/chat/${assistant.id}`}
									className="main-content-button home-button"
								>
									Use Agent
								</Link>
								<select
									onChange={(e) =>
										handleShare(assistant, "assistant", e.target.value)
									}
									defaultValue=""
								>
									<option value="" disabled>
										Share with department
									</option>
									{departments.map((dept) => (
										<option key={dept.id} value={dept.id}>
											{dept.name}
										</option>
									))}
								</select>
							</div>
						))}
					</div>
				</div>
				<div className="section">
					<h3>Personal Flows</h3>
					<div className="cards">
						{personalFlows.map((flow) => (
							<div
								key={flow.id}
								className={`card card-color-${switchChecked} card-border`}
								style={{ borderTop: "2px solid transparent" }}
							>
								<div className="ttl">
									{flow.templateName?.toUpperCase() || "UNNAMED FLOW"}
								</div>
								<p>Flow</p>
								<Link
									to={`/users/${user.uid}/workflows/${flow.id}`}
									className="main-content-button home-button"
								>
									Continue Flow
								</Link>
								<select
									onChange={(e) => handleShare(flow, "flow", e.target.value)}
									defaultValue=""
								>
									<option value="" disabled>
										Share with department
									</option>
									{departments.map((dept) => (
										<option key={dept.id} value={dept.id}>
											{dept.name}
										</option>
									))}
								</select>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PersonalDepartments;
