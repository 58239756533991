import React, { useState, useEffect } from "react";
import BotTemplate from "./BotTemplate";
import "./Styles/CreateBotForm.css";
import OpenAI from "openai";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPaperPlane,
	faSpinner,
	faWandMagicSparkles,
	faPencilAlt,
	faUserTie,
	faUserGroup,
	faPerson,
} from "@fortawesome/free-solid-svg-icons";

// Initialize OpenAI with your API key
const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

const CreateBotForm = ({ user, switchChecked }) => {
	const [botName, setBotName] = useState("");
	const [instructions, setInstructions] = useState("");
	const [description, setDescription] = useState("");
	// eslint-disable-next-line
	const [briefDescription, setBriefDescription] = useState("");
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line
	const [error, setError] = useState("");
	const [creationMode, setCreationMode] = useState("ai");
	const navigate = useNavigate();

	const [conversation, setConversation] = useState([
		{
			from: "ai",
			message:
				"Hello, I am here to help you create a new assistant. Please start by telling me what you would like the assistant to do.",
		},
	]);
	const [input, setInput] = useState("");
	const [userResponses, setUserResponses] = useState([]);
	const [step, setStep] = useState(0);

	const [selectedMode, setSelectedMode] = useState(null);

	// Toggle between "scratch" and "AI" mode
	// eslint-disable-next-line
	const toggleCreationMode = () => {
		setCreationMode(creationMode === "scratch" ? "ai" : "scratch");
		resetConversation();
	};

	// Reset the conversation and states when switching modes
	const resetConversation = () => {
		setConversation([
			{
				from: "ai",
				message:
					"Hello, I am here to help you create a new assistant. Please start by telling me what you would like the assistant to do.",
			},
		]);
		setInput("");
		setUserResponses([]);
		setStep(0);
		setError("");
	};

	// Handle user input in the chat interface
	const handleUserInput = () => {
		if (input.trim() === "") return;

		setConversation((prev) => [...prev, { from: "user", message: input }]);
		setUserResponses((prev) => [...prev, input]);
		setInput("");

		setStep((prev) => prev + 1);
	};

	// After each step, fetch the next AI-generated question or trigger bot creation
	useEffect(() => {
		if (creationMode === "ai" && step > 0 && step < 3) {
			fetchNextQuestion();
		} else if (creationMode === "ai" && step === 3) {
			generateBotWithAI();
		}
		// eslint-disable-next-line
	}, [step]);

	// Fetch the next dynamic question from AI
	const fetchNextQuestion = async () => {
		try {
			setLoading(true);
			const response = await openai.chat.completions.create({
				model: "gpt-4o-mini",
				messages: [
					{
						role: "system",
						content:
							"You are an AI assistant that helps users create custom assistants. Based on the user's input, ask follow-up questions to understand their needs better. Please note that these custom assistants can **only** engage in text-based conversations and provide information from their internal knowledge base. They **cannot** connect to external systems, perform actions, or execute tasks outside of the chat interface. **Do not** suggest or inquire about any capabilities beyond simple conversation and knowledge retrieval.",
					},
					{
						role: "user",
						content: `The user has provided the following so far: ${userResponses.join(
							", "
						)}. Ask a follow-up question to clarify their needs.`,
					},
				],
			});

			const aiMessage = response.choices[0].message.content;
			setConversation((prev) => [...prev, { from: "ai", message: aiMessage }]);
		} catch (error) {
			console.error("Error fetching the next question:", error);
			setConversation((prev) => [
				...prev,
				{
					from: "ai",
					message:
						"There was an error getting the next question. Please try again.",
				},
			]);
		} finally {
			setLoading(false);
		}
	};

	// Generate bot configuration using AI based on user responses
	const generateBotWithAI = async () => {
		try {
			setConversation((prev) => [
				...prev,
				{
					from: "ai",
					message: "Hang tight while I construct your new AI assistant...",
				},
			]);
			setLoading(true);

			const response = await openai.chat.completions.create({
				model: "o1-mini",
				messages: [
					{
						role: "user",
						content: `You are an AI assistant designed to generate task-specific bot configurations based on a user's brief description of their needs. Your response should be in valid JSON format without any markdown formatting.
Your tasks include:

Understanding User Requirements:
- Based on the user's brief, determine the appropriate domain (e.g., customer service, event planning, data analysis, etc.).
- Clarify any ambiguous points by asking the user for more details if necessary.

Creating Bot Configurations:
- Use the description provided by the user to generate a new bot configuration, similar to the existing ones such as CustomerServiceBot, DataAnalysisBot, EventPlanningBot, etc.
- Include the following in the bot configuration:
  - Bot Name: Choose an appropriate name for the bot based on its purpose.
  - Instructions: Write a detailed set of instructions outlining the bot's purpose, tasks, and behaviors. Ensure the bot handles tasks related to the user's requirements.
  - Description: Write a brief summary of what the bot does.

Maintaining Consistency with Existing Bots:
- Ensure that the generated configuration is consistent with the structure of existing bots like the CustomerServiceBot or DataAnalysisBot, aligning with their formatting, task specifications, and the requirement to reference a knowledge base when relevant.

Including Source References:
- If the bot is expected to provide information based on an external or internal knowledge base, ensure the bot references the relevant sources in its responses.

Finalizing and Delivering:
- Present the generated bot configuration in a clear, user-friendly format for review.
- Optionally, offer to modify the bot configuration based on user feedback or additional requirements.

Your response should be in the following JSON format:
{
  "name": "Bot Name",
  "instructions": "Detailed instructions for the bot",
  "description": "Brief description of what the bot does"
}

You Must Ensure that your response is valid JSON without any markdown formatting.`,
					},
					{
						role: "user",
						content: `Generate a bot configuration with name, instructions, and description based on the following: ${userResponses.join(
							", "
						)}`,
					},
				],
			});

			const botData = response.choices[0].message.content;
			const cleanedBotData = botData.replace(/^\s*```json|```$/g, "").trim();

			try {
				const botJson = JSON.parse(cleanedBotData);
				setBotName(botJson.name || "New Bot");
				setInstructions(botJson.instructions || "No instructions provided.");
				setDescription(botJson.description || "No description provided.");
				setIsSubmitted(true);
			} catch (parseError) {
				console.error("Error parsing AI response:", parseError);
				setError(
					`Failed to parse AI response. Error: ${parseError.message}. Please try again.`
				);
			}
		} catch (err) {
			console.error("Error generating bot with AI:", err);
			setError(
				`Failed to generate bot. Error: ${err.message}. Please try again.`
			);
		} finally {
			setLoading(false);
		}
	};

	// Handle manual submission for "Create from Scratch" mode
	const handleSubmit = (e) => {
		e.preventDefault();
		setIsSubmitted(true);
	};

	const renderContent = () => {
		switch (selectedMode) {
			case "ai":
				return (
					<div className="chat-interface">
						<div className="chat-container">
							{conversation.map((msg, index) => (
								<div key={index} className={`message ${msg.from}`}>
									{msg.message}
								</div>
							))}
							{loading && (
								<div className="message ai typing-indicator">
									<span></span>
									<span></span>
									<span></span>
								</div>
							)}
						</div>
						<div className="chat-input-container-creation">
							<textarea
								className="chat-textarea"
								placeholder="Type your response..."
								value={input}
								onChange={(e) => setInput(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === "Enter" && !loading) {
										e.preventDefault(); // Prevent new line
										handleUserInput();
									}
								}}
							/>
							<button
								className="main-content-button"
								onClick={handleUserInput}
								disabled={loading}
							>
								{loading ? (
									<FontAwesomeIcon icon={faSpinner} spin />
								) : (
									<FontAwesomeIcon icon={faPaperPlane} />
								)}
							</button>
						</div>
					</div>
				);
			case "scratch":
				return (
					<form onSubmit={handleSubmit} className="main-form">
						<div className="form-group">
							{/* <label htmlFor="botName">Agent's Name</label>
							<input
								type="text"
								id="botName"
								className="flow-name main-flows-name"
								value={botName}
								onChange={(e) => setBotName(e.target.value)}
								required
								placeholder="Enter Custom Name"
							/> */}
						</div>
						<div className="form-group">
							<label htmlFor="instructions">Custom Instructions</label>
							<textarea
								placeholder="Enter Custom Instructions as to what the Agents's role will be, and how it should act. These Instructions will dictate how well the agent works. The more detail and reasoning input here will directly reflect the agent's responses and thinking..."
								id="instructions"
								value={instructions}
								className="chat-textarea"
								onChange={(e) => {
									setInstructions(e.target.value);
									setDescription("Custom Agent Builder");
								}}
								required
							></textarea>
						</div>
						{/* <div className="form-group">
							<label htmlFor="description">Custom Description</label>
							<textarea
								id="description"
								placeholder="Brief description of the Assistant's actions ..."
								className="chat-textarea"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								required
							></textarea>
						</div> */}
						<button type="submit" className="main-content-button">
							Create Assistant
						</button>
					</form>
				);
			case "pro":
				// Navigate to pro agents page
				navigate("/pro-agents");
				return null;
			case "myAgents":
				navigate("/my-agents");
				return null;
			case "sharedAgents":
				navigate("/shared-agents");
				return null;
			default:
				return null;
		}
	};

	return (
		<div>
			{!isSubmitted ? (
				<div className={`main-content main-color-${switchChecked}`}>
					{!selectedMode && <h2>Create a new Agents</h2>}
					{!selectedMode ? (
						<div className="creation-options creation-options-1">
							<button
								className="main-content-button"
								onClick={() => setSelectedMode("ai")}
							>
								<FontAwesomeIcon icon={faWandMagicSparkles} />
								Generate New Agent with AI's help <br /> (Recommended)
							</button>
							<button
								className="main-content-button"
								onClick={() => setSelectedMode("pro")}
							>
								<FontAwesomeIcon icon={faUserTie} />
								Use Pro Agent <br /> (Best General Agents)
							</button>
							<button
								className="main-content-button"
								onClick={() => setSelectedMode("scratch")}
							>
								<FontAwesomeIcon icon={faPencilAlt} />
								Advanced Creation <br /> (From Scratch)
							</button>
						</div>
					) : (
						<>
							<div className="top-bar">
								<button
									className="main-content-button"
									onClick={() => setSelectedMode(null)}
								>
									Back to Options
								</button>
							</div>
							{renderContent()}
						</>
					)}
					{!selectedMode && (
						<div>
							<h2 style={{ marginTop: "10vh" }}>View Existing Agents</h2>
							<div className="creation-options creation-options-2">
								<button
									className="main-content-button"
									onClick={() => setSelectedMode("myAgents")}
								>
									<FontAwesomeIcon icon={faPerson} />
									My Agents
								</button>
								<button
									className="main-content-button"
									onClick={() => setSelectedMode("sharedAgents")}
								>
									<FontAwesomeIcon icon={faUserGroup} />
									Shared Agents
								</button>
							</div>
						</div>
					)}
				</div>
			) : (
				<BotTemplate
					user={user}
					botName={botName}
					instructions={instructions}
					description={description}
					switchChecked={switchChecked}
				/>
			)}
		</div>
	);
};

export default CreateBotForm;
