import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
	collection,
	getDocs,
	doc,
	getDoc,
	query,
	where,
} from "firebase/firestore";
import { Link } from "react-router-dom";

const SharedDepartments = ({ user, switchChecked }) => {
	const [departments, setDepartments] = useState([]);
	const [resources, setResources] = useState({});
	const [teamId, setTeamId] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);
				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					setTeamId(userData.teamId);
				}
			} catch (error) {
				console.error("Error fetching user data: ", error);
			}
		};

		fetchUserData();
	}, [user.uid]);

	useEffect(() => {
		const fetchDepartmentsAndResources = async () => {
			if (!teamId) return;

			setLoading(true);
			try {
				const departmentsSnapshot = await getDocs(
					collection(db, `teams/${teamId}/departments`)
				);
				const departmentsList = departmentsSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setDepartments(departmentsList);

				const assistantsSnapshot = await getDocs(
					query(
						collection(db, `teams/${teamId}/assistants`),
						where("isPersonal", "==", false)
					)
				);
				const assistants = assistantsSnapshot.docs.map((doc) => ({
					id: doc.id,
					type: doc.data().type || "agent",
					...doc.data(),
				}));

				const flowsSnapshot = await getDocs(
					query(
						collection(db, `teams/${teamId}/flows`),
						where("isPersonal", "==", false)
					)
				);
				const flows = flowsSnapshot.docs.map((doc) => ({
					id: doc.id,
					type: "flow",
					...doc.data(),
				}));

				const organizedResources = {};
				[...assistants, ...flows].forEach((resource) => {
					if (resource.departments && Array.isArray(resource.departments)) {
						resource.departments.forEach((deptId) => {
							if (!organizedResources[deptId]) {
								organizedResources[deptId] = {
									agents: [],
									flows: [],
								};
							}
							if (resource.type.toLowerCase().includes("agent")) {
								organizedResources[deptId].agents.push(resource);
							} else if (resource.type === "flow") {
								organizedResources[deptId].flows.push(resource);
							}
						});
					}
				});

				setResources(organizedResources);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching departments and resources: ", error);
				setLoading(false);
			}
		};

		fetchDepartmentsAndResources();
	}, [teamId]);

	if (loading) {
		return (
			<div
				className={`main-content template-content main-color-${switchChecked}`}
			>
				Loading...
			</div>
		);
	}

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<h2>Departments</h2>
			<div
				className={`main-rows main-rows-${switchChecked}`}
				style={{ flexWrap: "wrap", height: "80vh", overflowY: "scroll" }}
			>
				{departments.map((department) => (
					<div
						key={department.id}
						className="section department-card"
						style={{
							borderTop: `10px solid ${department.color || "#000000"}`,
							borderRadius: "8px",
							overflow: "hidden",
							backgroundColor: "#333", // Adjust this to match your dark theme
							marginBottom: "20px",
							boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
							minWidth: "300px",
							flexGrow: "1",
							flexBasis: "300px",
							height: "60vh",
							overflowY: "scroll",
						}}
					>
						<h3
							style={{
								padding: "15px",
								margin: 0,
								color: "white",
							}}
						>
							{department.name}
						</h3>
						<div style={{ padding: "15px", width: "100%" }}>
							{resources[department.id] &&
							(resources[department.id].agents.length > 0 ||
								resources[department.id].flows.length > 0) ? (
								<div className="cards" style={{ width: "100%" }}>
									{resources[department.id].agents.map((agent) => (
										<Link
											key={agent.id}
											to={`/chat/${agent.id}`}
											className={`card card-link card-color-${switchChecked} card-border`}
											style={{
												borderColor: "rgba(255, 255, 255, 0.1)",
											}}
										>
											<div className="ttl">{agent.name.toUpperCase()}</div>
											<p>{agent.type}</p>
											<button className="main-content-button home-button">
												Use Agent
											</button>
										</Link>
									))}
									{resources[department.id].flows.map((flow) => (
										<Link
											key={flow.id}
											to={`/users/${user.uid}/workflows/${flow.id}`}
											className={`card card-link card-color-${switchChecked} card-border`}
											style={{
												borderColor: "rgba(255, 255, 255, 0.1)",
											}}
										>
											<div className="ttl">
												{flow.templateName?.toUpperCase() || "UNNAMED FLOW"}
											</div>
											<p>Flow</p>
											<button className="main-content-button home-button">
												Continue Flow
											</button>
										</Link>
									))}
								</div>
							) : (
								<div
									className={`card card-color-${switchChecked} card-border`}
									style={{
										borderColor: "rgba(255, 255, 255, 0.1)",
										width: "auto",
									}}
								>
									<p>No resources assigned to this department.</p>
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default SharedDepartments;
