import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
	collection,
	getDocs,
	doc,
	getDoc,
	query,
	where,
	deleteDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import "./Styles/AllThreads.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const Assistants = ({ user, switchChecked, selectedDepartment }) => {
	const [assistants, setAssistants] = useState([]);
	const [teamId, setTeamId] = useState(null);
	// eslint-disable-next-line
	const [userRole, setUserRole] = useState(null);
	const [departments, setDepartments] = useState({});

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);
				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					setUserRole(userData.levelType);
					setTeamId(userData.teamId);
				}
			} catch (error) {
				console.error("Error fetching user data: ", error);
			}
		};

		fetchUserData();
	}, [user.uid]);

	useEffect(() => {
		const fetchDepartments = async () => {
			if (teamId) {
				try {
					const departmentsSnapshot = await getDocs(
						collection(db, `teams/${teamId}/departments`)
					);
					const departmentsData = {};
					departmentsSnapshot.forEach((doc) => {
						departmentsData[doc.id] = {
							name: doc.data().name,
							color: doc.data().color,
						};
					});
					setDepartments(departmentsData);
				} catch (error) {
					console.error("Error fetching departments: ", error);
				}
			}
		};

		fetchDepartments();
	}, [teamId]);

	useEffect(() => {
		const fetchAssistants = async () => {
			try {
				let assistantsList = [];
				if (teamId) {
					let userAssistantsQuery = query(
						collection(db, `teams/${teamId}/assistants`),
						where("createdBy", "==", user.uid)
					);

					const querySnapshot = await getDocs(userAssistantsQuery);
					querySnapshot.forEach((doc) => {
						assistantsList.push({ id: doc.id, ...doc.data() });
					});

					if (selectedDepartment && selectedDepartment.id !== "all") {
						assistantsList = assistantsList.filter(
							(assistant) =>
								assistant.departments &&
								assistant.departments.includes(selectedDepartment.id)
						);
					}
				}
				setAssistants(assistantsList);
			} catch (error) {
				console.error("Error fetching assistants: ", error);
			}
		};

		if (teamId) {
			fetchAssistants();
		}
	}, [teamId, user.uid, selectedDepartment]);

	const renderDepartments = (assistant) => {
		if (assistant.departments && assistant.departments.length > 0) {
			return assistant.departments
				.map((deptId) => departments[deptId]?.name || deptId)
				.join(", ");
		}
		return "Personal";
	};

	const handleDeleteAgent = async (agentId) => {
		if (window.confirm("Are you sure you want to delete this agent?")) {
			try {
				await deleteDoc(doc(db, `teams/${teamId}/assistants`, agentId));
				setAssistants(
					assistants.filter((assistant) => assistant.id !== agentId)
				);
				alert("Agent deleted successfully!");
			} catch (error) {
				console.error("Error deleting agent:", error);
				alert("Failed to delete agent. Please try again.");
			}
		}
	};

	const renderAssistantCard = (assistant) => {
		const departmentColor =
			assistant.departments && assistant.departments.length > 0
				? departments[assistant.departments[0]]?.color
				: "transparent";

		return (
			<li
				key={assistant.id}
				className={`card card-color-${switchChecked} card-border`}
				style={{ borderTop: `10px solid ${departmentColor}` }}
			>
				<div className="card-header">
					<h3>{assistant.name.toUpperCase()}</h3>
					<p>Department: {renderDepartments(assistant)}</p>
				</div>
				<div className="card-actions">
					<Link
						onClick={() => handleDeleteAgent(assistant.id)}
						className="main-content-button delete-button"
					>
						<FontAwesomeIcon icon={faTrashAlt} />
					</Link>
					<Link to={`/chat/${assistant.id}`} className="main-content-button">
						Use Agent
					</Link>
				</div>
			</li>
		);
	};

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<div className="template-header">
				<h2>My Agents</h2>
			</div>
			{assistants.length === 0 ? (
				<div
					className={`empty-threads card card-color-${switchChecked} card-border`}
					style={{ borderTop: "2px solid transparent" }}
				>
					<h3>Oops! It doesn't look like you have any existing agents.</h3>
					<h4>Let's create one!</h4>
					<Link to="/templates" className="main-content-button">
						Create new agent
					</Link>
				</div>
			) : (
				<ul className="card-list my-threads">
					{assistants.map((assistant) => renderAssistantCard(assistant))}
				</ul>
			)}
		</div>
	);
};

export default Assistants;
