import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
	collection,
	getDocs,
	doc,
	getDoc,
	query,
	where,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import "./Styles/AllThreads.css";

const SharedAssistants = ({ user, switchChecked, selectedDepartment }) => {
	const [sharedAssistants, setSharedAssistants] = useState([]);
	const [teamId, setTeamId] = useState(null);
	const [departmentsMap, setDepartmentsMap] = useState({});

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);
				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					setTeamId(userData.teamId);
				}
			} catch (error) {
				console.error("Error fetching user data: ", error);
			}
		};

		fetchUserData();
	}, [user.uid]);

	useEffect(() => {
		const fetchDepartments = async () => {
			if (teamId) {
				try {
					const departmentsSnapshot = await getDocs(
						collection(db, `teams/${teamId}/departments`)
					);
					let departmentsData = {};
					departmentsSnapshot.forEach((doc) => {
						departmentsData[doc.id] = {
							name: doc.data().name,
							color: doc.data().color,
						};
					});
					setDepartmentsMap(departmentsData);
				} catch (error) {
					console.error("Error fetching departments: ", error);
				}
			}
		};

		if (teamId) {
			fetchDepartments();
		}
	}, [teamId]);

	useEffect(() => {
		const fetchSharedAssistants = async () => {
			try {
				let assistantsList = [];
				if (teamId) {
					const sharedAssistantsQuery = query(
						collection(db, `teams/${teamId}/assistants`),
						where("isPersonal", "==", false) // Filter for shared assistants
					);

					const querySnapshot = await getDocs(sharedAssistantsQuery);
					querySnapshot.forEach((doc) => {
						assistantsList.push({ id: doc.id, ...doc.data() });
					});

					// Filter assistants based on selectedDepartment
					if (selectedDepartment && selectedDepartment.id !== "all") {
						assistantsList = assistantsList.filter(
							(assistant) =>
								assistant.departments &&
								assistant.departments.includes(selectedDepartment.id)
						);
					}
				}
				setSharedAssistants(assistantsList);
			} catch (error) {
				console.error("Error fetching shared assistants: ", error);
			}
		};

		if (teamId) {
			fetchSharedAssistants();
		}
	}, [teamId, selectedDepartment]);

	const renderDepartments = (assistant) => {
		if (assistant.departments && assistant.departments.length > 0) {
			// Map department IDs to department names
			const departmentNames = assistant.departments.map(
				(deptId) => departmentsMap[deptId]?.name || deptId // Fallback to deptId if name is not found
			);
			return departmentNames.join(", ");
		}
		return "No Department";
	};

	const renderAssistantCard = (assistant) => {
		const departmentColor =
			assistant.departments && assistant.departments.length > 0
				? departmentsMap[assistant.departments[0]]?.color
				: "transparent";

		return (
			<li
				key={assistant.id}
				className={`card card-color-${switchChecked} card-border`}
				style={{ borderTop: `10px solid ${departmentColor}` }}
			>
				<div className="card-header">
					<h3>{assistant.name.toUpperCase()}</h3>
					<p>Department: {renderDepartments(assistant)}</p>
				</div>
				<Link to={`/chat/${assistant.id}`} className="main-content-button">
					Use Agent
				</Link>
			</li>
		);
	};

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<div className="template-header">
				<h2>Shared Agents</h2>
			</div>
			{sharedAssistants.length === 0 ? (
				<div
					className={`empty-threads card card-color-${switchChecked} card-border`}
					style={{ borderTop: "2px solid transparent" }}
				>
					<h3>No shared agents available.</h3>
					<h4>Check back later or create new shared agents.</h4>
					<Link to="/templates" className="main-content-button">
						Create new shared agent
					</Link>
				</div>
			) : (
				<ul className="card-list my-threads">
					{sharedAssistants.map((assistant) => renderAssistantCard(assistant))}
				</ul>
			)}
		</div>
	);
};

export default SharedAssistants;
