import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
	collection,
	getDocs,
	doc,
	getDoc,
	query,
	where,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import "../Bots/Styles/AllThreads.css";

const SharedFlows = ({ user, switchChecked, selectedDepartment }) => {
	const [sharedWorkflows, setSharedWorkflows] = useState([]);
	// eslint-disable-next-line
	const [teamId, setTeamId] = useState(null);
	const [departmentsMap, setDepartmentsMap] = useState({});

	useEffect(() => {
		const loadUserData = async () => {
			try {
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);

				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					const teamId = userData.teamId;
					setTeamId(teamId);

					if (teamId) {
						// Fetch departments first to create a mapping
						const departmentsSnapshot = await getDocs(
							collection(db, `teams/${teamId}/departments`)
						);
						let departmentsData = {};
						departmentsSnapshot.forEach((doc) => {
							departmentsData[doc.id] = {
								name: doc.data().name,
								color: doc.data().color,
							};
						});
						setDepartmentsMap(departmentsData);

						// Fetch shared flows
						const sharedFlowsQuery = query(
							collection(db, `teams/${teamId}/flows`),
							where("isPersonal", "==", false) // Filter for shared flows
						);

						const querySnapshot = await getDocs(sharedFlowsQuery);
						let loadedWorkflows = [];
						querySnapshot.forEach((doc) => {
							loadedWorkflows.push({ id: doc.id, ...doc.data() });
						});

						// Filter workflows based on selectedDepartment
						if (selectedDepartment && selectedDepartment.id !== "all") {
							loadedWorkflows = loadedWorkflows.filter(
								(workflow) =>
									workflow.departments &&
									workflow.departments.includes(selectedDepartment.id)
							);
						}

						setSharedWorkflows(loadedWorkflows);
					} else {
						console.error("User is not part of a team");
					}
				} else {
					console.error("User document not found");
				}
			} catch (error) {
				console.error("Error loading shared workflows: ", error);
			}
		};

		loadUserData();
	}, [user.uid, selectedDepartment]);

	const renderDepartments = (workflow) => {
		if (workflow.departments && workflow.departments.length > 0) {
			const departmentNames = workflow.departments.map(
				(deptId) => departmentsMap[deptId]?.name || deptId // Fallback to deptId if name is not found
			);
			return departmentNames.join(", ");
		}
		return "No Department";
	};

	const renderWorkflowCard = (workflow) => {
		const departmentColor =
			workflow.departments && workflow.departments.length > 0
				? departmentsMap[workflow.departments[0]]?.color
				: "transparent";

		return (
			<li key={workflow.id}>
				<Link
					to={`/users/${user.uid}/workflows/${workflow.id}`}
					className={`card card-link card-color-${switchChecked} card-border`}
					style={{ borderTop: `10px solid ${departmentColor}` }}
				>
					<div className="ttl">{workflow.templateName?.toUpperCase()}</div>
					<p>Department: {renderDepartments(workflow)}</p>
					<button className="main-content-button home-button">
						Continue Flow
					</button>
				</Link>
			</li>
		);
	};

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<div className="template-header">
				<h2>Shared Flows</h2>
			</div>
			{sharedWorkflows.length === 0 ? (
				<div
					className={`empty-threads card card-color-${switchChecked} card-border`}
					style={{ borderTop: "2px solid transparent" }}
				>
					<h3>No shared flows available.</h3>
					<h4>Check back later or create new shared flows.</h4>
					<Link
						to={`/users/${user.uid}/workflows`}
						className="main-content-button"
					>
						Create new flow
					</Link>
				</div>
			) : (
				<ul className="card-list my-threads">
					{sharedWorkflows.map((workflow) => renderWorkflowCard(workflow))}
				</ul>
			)}
		</div>
	);
};

export default SharedFlows;
