import React, { useState, useEffect } from "react";
import "../Styles/CustomizeModal.css";

const CustomizeModal = ({ isOpen, onClose, currentCustomization, onSave }) => {
	const [customization, setCustomization] = useState(currentCustomization);

	useEffect(() => {
		setCustomization(currentCustomization);
	}, [currentCustomization]);

	if (!isOpen) return null;

	const handleChange = (e) => {
		const { name, checked } = e.target;
		setCustomization((prev) => ({ ...prev, [name]: checked }));
	};

	const handleSave = () => {
		onSave(customization);
	};

	return (
		<div className="customize-modal-overlay">
			<div className="customize-modal-content">
				<h2>Customize Dashboard</h2>
				<form>
					<div>
						<label>
							<input
								type="checkbox"
								name="showMostUsed"
								checked={customization.showMostUsed}
								onChange={handleChange}
							/>
							Show Most Used
						</label>
					</div>
					<div>
						<label>
							<input
								type="checkbox"
								name="showRecentlyCreated"
								checked={customization.showRecentlyCreated}
								onChange={handleChange}
							/>
							Show Recently Created
						</label>
					</div>
					<div>
						<label>
							<input
								type="checkbox"
								name="showPast30Days"
								checked={customization.showPast30Days}
								onChange={handleChange}
							/>
							Show Past 30 Days
						</label>
					</div>
					<div>
						<label>
							<input
								type="checkbox"
								name="showMyTeam"
								checked={customization.showMyTeam}
								onChange={handleChange}
							/>
							Show My Team
						</label>
					</div>
					<div>
						<label>
							<input
								type="checkbox"
								name="showMyMessages"
								checked={customization.showMyMessages}
								onChange={handleChange}
							/>
							Show My Messages
						</label>
					</div>
				</form>
				<div className="customize-modal-buttons">
					<button onClick={handleSave}>Save</button>
					<button onClick={onClose}>Cancel</button>
				</div>
			</div>
		</div>
	);
};

export default CustomizeModal;
