import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { auth } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import Dashboard from "./Components/Dashboard";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import JoinTeam from "./Components/JoinTeam";
import PasswordReset from "./Components/PasswordReset";
import { db } from "./firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";
import "./variables.css";

function App() {
	const [user, setUser] = useState(null);
	const [switchChecked, setSwitchChecked] = useState(
		localStorage.getItem("switchChecked") === "true"
	);
	const [startingTokens, setStartingTokens] = useState(0);
	const [tokenCount, setTokenCount] = useState(0);
	const [selectedDepartment, setSelectedDepartment] = useState(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setUser(user);
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		let userUnsubscribe;
		let teamUnsubscribe;

		if (user && user.uid) {
			const userDocRef = doc(db, `users/${user.uid}`);
			userUnsubscribe = onSnapshot(userDocRef, (userDocSnap) => {
				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					const teamId = userData.teamId;

					if (teamId) {
						const teamDocRef = doc(db, `teams/${teamId}`);
						teamUnsubscribe = onSnapshot(teamDocRef, (teamDocSnap) => {
							if (teamDocSnap.exists()) {
								const teamData = teamDocSnap.data();
								setStartingTokens(teamData.tokens || 0);
								setTokenCount(teamData.tokens - (teamData.tokenCount || 0));
							}
						});
					} else {
						console.log("User does not belong to a team");
						setStartingTokens(0);
						setTokenCount(0);
					}
				}
			});
		}

		return () => {
			if (userUnsubscribe) {
				userUnsubscribe();
			}
			if (teamUnsubscribe) {
				teamUnsubscribe();
			}
		};
	}, [user]);

	return (
		<Router>
			<div className="App">
				<Routes>
					<Route
						path="/login"
						element={<Login setUser={setUser} switchChecked={switchChecked} />}
					/>
					<Route
						path="/signup"
						element={<Signup setUser={setUser} switchChecked={switchChecked} />}
					/>
					<Route
						path="/*"
						element={
							<Dashboard
								user={user}
								switchChecked={switchChecked}
								setSwitchChecked={setSwitchChecked}
								startingTokens={startingTokens}
								tokenCount={tokenCount}
								selectedDepartment={selectedDepartment}
								setSelectedDepartment={setSelectedDepartment}
							/>
						}
					/>
					<Route
						path="/join-team"
						element={
							<JoinTeam setUser={setUser} switchChecked={switchChecked} />
						}
					/>
					<Route
						path="/__/auth/action"
						element={<PasswordReset switchChecked={switchChecked} />}
					/>
				</Routes>
			</div>
		</Router>
	);
}

export default App;
