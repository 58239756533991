import React, { useState, useEffect, useCallback } from "react";
import { db, storage } from "../../firebaseConfig";
import {
	collection,
	getDocs,
	addDoc,
	doc,
	getDoc,
	query,
	where,
} from "firebase/firestore";
import { ref, getBlob } from "firebase/storage";
import OpenAI from "openai";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./Styles/SocialMediaBot.css";

const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

const BotTemplate = ({
	user,
	botName,
	instructions,
	description,
	switchChecked,
}) => {
	const [personalFiles, setPersonalFiles] = useState([]);
	const [sharedFiles, setSharedFiles] = useState([]);
	const [filteredPersonalFiles, setFilteredPersonalFiles] = useState([]);
	const [filteredSharedFiles, setFilteredSharedFiles] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [loading, setLoading] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [customBotName, setCustomBotName] = useState("");
	const [isReady, setIsReady] = useState(false);
	const [newDocID, setNewDocID] = useState("");
	const [trainingStage, setTrainingStage] = useState(0);
	const [teamId, setTeamId] = useState("");
	// eslint-disable-next-line
	const [assistantId, setAssistantId] = useState(null);
	// eslint-disable-next-line
	const [threadId, setThreadId] = useState(null);
	// eslint-disable-next-line
	const [departments, setDepartments] = useState([]);
	const [selectedDepartments, setSelectedDepartments] = useState([]);
	const [enableImageCreation, setEnableImageCreation] = useState(false);

	const fetchFiles = useCallback(async () => {
		try {
			if (!user.uid) return;

			const personalFilesQuery = query(
				collection(db, `users/${user.uid}/knowledgebase`)
			);
			const personalFilesSnapshot = await getDocs(personalFilesQuery);
			const personalFilesList = personalFilesSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
				isPersonal: true,
			}));
			setPersonalFiles(personalFilesList);
			setFilteredPersonalFiles(personalFilesList);

			if (teamId) {
				const sharedWithQuery = query(
					collection(db, `teams/${teamId}/knowledgebase`),
					where("sharedWith", "array-contains", user.email)
				);

				const sharedDepartmentsQuery = query(
					collection(db, `teams/${teamId}/knowledgebase`),
					where("sharedDepartments", "!=", [])
				);

				const [sharedWithSnapshot, sharedDepartmentsSnapshot] =
					await Promise.all([
						getDocs(sharedWithQuery),
						getDocs(sharedDepartmentsQuery),
					]);

				const sharedWithList = sharedWithSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
					isPersonal: false,
				}));

				const sharedDepartmentsList = sharedDepartmentsSnapshot.docs.map(
					(doc) => ({
						id: doc.id,
						...doc.data(),
						isPersonal: false,
					})
				);

				const combinedList = [...sharedWithList, ...sharedDepartmentsList];
				const uniqueList = Array.from(
					new Set(combinedList.map((doc) => doc.id))
				).map((id) => combinedList.find((doc) => doc.id === id));

				setSharedFiles(uniqueList);
				setFilteredSharedFiles(uniqueList);
			}
		} catch (error) {
			console.error("Error fetching files: ", error);
		}
	}, [user.uid, user.email, teamId]);

	useEffect(() => {
		const fetchUserAccountType = async () => {
			try {
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);
				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					setTeamId(userData.teamId);
				}
			} catch (error) {
				console.error("Error fetching user account type: ", error);
			}
		};

		fetchUserAccountType();
	}, [user.uid]);

	useEffect(() => {
		if (teamId) {
			fetchFiles();
		}
	}, [fetchFiles, teamId]);

	useEffect(() => {
		setFilteredPersonalFiles(
			personalFiles.filter((file) =>
				file.name.toLowerCase().includes(searchQuery.toLowerCase())
			)
		);
		setFilteredSharedFiles(
			sharedFiles.filter((file) =>
				file.name.toLowerCase().includes(searchQuery.toLowerCase())
			)
		);
	}, [searchQuery, personalFiles, sharedFiles]);

	useEffect(() => {
		const imageExtensions = [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".svg"];

		const isImageFile = (fileName) => {
			const extension = "." + fileName.split(".").pop().toLowerCase();
			return imageExtensions.includes(extension);
		};

		setFilteredPersonalFiles(
			personalFiles.filter(
				(file) =>
					file.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
					!isImageFile(file.name)
			)
		);
		setFilteredSharedFiles(
			sharedFiles.filter(
				(file) =>
					file.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
					!isImageFile(file.name)
			)
		);
	}, [searchQuery, personalFiles, sharedFiles]);

	useEffect(() => {
		const fetchDepartments = async () => {
			if (teamId) {
				const departmentsSnapshot = await getDocs(
					collection(db, `teams/${teamId}/departments`)
				);
				const departmentsList = departmentsSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setDepartments(departmentsList);
			}
		};
		fetchDepartments();
	}, [teamId]);
	// eslint-disable-next-line
	const handleDepartmentChange = (e) => {
		const departmentId = e.target.value;
		setSelectedDepartments((prev) =>
			prev.includes(departmentId)
				? prev.filter((id) => id !== departmentId)
				: [...prev, departmentId]
		);
	};

	const handleFileSelection = (fileId, isPersonal) => {
		setSelectedFiles((prevSelectedFiles) => {
			const fileList = isPersonal ? personalFiles : sharedFiles;
			const selectedFile = fileList.find((file) => file.id === fileId);

			if (prevSelectedFiles.some((file) => file.id === fileId)) {
				return prevSelectedFiles.filter((file) => file.id !== fileId);
			} else {
				return [...prevSelectedFiles, selectedFile];
			}
		});
	};

	const decryptFile = async (arrayBuffer, keyBuffer, ivBuffer) => {
		console.log(
			"Key (hex):",
			Array.from(keyBuffer)
				.map((b) => b.toString(16).padStart(2, "0"))
				.join(" ")
		);
		console.log(
			"IV (hex):",
			Array.from(ivBuffer)
				.map((b) => b.toString(16).padStart(2, "0"))
				.join(" ")
		);

		const algorithm = { name: "AES-CBC", iv: ivBuffer };
		try {
			const key = await crypto.subtle.importKey(
				"raw",
				keyBuffer,
				algorithm,
				false,
				["decrypt"]
			);
			const decrypted = await crypto.subtle.decrypt(
				algorithm,
				key,
				arrayBuffer
			);
			console.log("Decryption successful, length:", decrypted.byteLength);
			return new Uint8Array(decrypted);
		} catch (error) {
			console.error("Decryption failed:", error);
			throw error;
		}
	};

	const decryptFileGCM = async (arrayBuffer, keyBuffer, ivBuffer) => {
		console.log(
			"Key (hex):",
			Array.from(keyBuffer)
				.map((b) => b.toString(16).padStart(2, "0"))
				.join(" ")
		);
		console.log(
			"IV (hex):",
			Array.from(ivBuffer)
				.map((b) => b.toString(16).padStart(2, "0"))
				.join(" ")
		);

		const algorithm = { name: "AES-GCM", iv: ivBuffer };
		try {
			const key = await crypto.subtle.importKey(
				"raw",
				keyBuffer,
				algorithm,
				false,
				["decrypt"]
			);
			const decrypted = await crypto.subtle.decrypt(
				algorithm,
				key,
				arrayBuffer
			);
			console.log("Decryption successful, length:", decrypted.byteLength);
			return new Uint8Array(decrypted);
		} catch (error) {
			console.error("Decryption failed:", error);
			throw error;
		}
	};

	const getFileContent = async (fileUrls, fileData) => {
		const files = []; // Array to hold decrypted files
		for (let i = 0; i < fileUrls.length; i++) {
			const fileUrl = fileUrls[i];
			const fileDataItem = fileData[i]; // Assuming fileData corresponds to fileUrls
			try {
				const fileRef = ref(storage, fileUrl);
				const blob = await getBlob(fileRef);
				const arrayBuffer = await blob.arrayBuffer();

				console.log("File size before decryption:", arrayBuffer.byteLength);

				const keyBuffer = new Uint8Array(fileDataItem.key);
				const ivBuffer = new Uint8Array(fileDataItem.iv);

				if (keyBuffer.length !== 16 && keyBuffer.length !== 32) {
					throw new Error(
						"Invalid key length. AES key must be either 128 bits (16 bytes) or 256 bits (32 bytes)."
					);
				}

				let decryptedData;
				try {
					decryptedData = await decryptFile(arrayBuffer, keyBuffer, ivBuffer);
				} catch (cbcError) {
					console.log("CBC decryption failed, attempting GCM...");
					try {
						decryptedData = await decryptFileGCM(
							arrayBuffer,
							keyBuffer,
							ivBuffer
						);
					} catch (gcmError) {
						throw new Error("Both CBC and GCM decryption failed");
					}
				}

				console.log("Decrypted data length:", decryptedData.length);

				if (decryptedData.length === 0) {
					throw new Error("Decrypted file is empty");
				}

				console.log(
					"Sample of decrypted content (hex):",
					Array.from(decryptedData.slice(0, 16))
						.map((b) => b.toString(16).padStart(2, "0"))
						.join(" ")
				);

				const decryptedBlob = new Blob([decryptedData], {
					type: "application/octet-stream",
				});
				let fileName = fileUrl.split("/").pop();
				fileName = fileName.split("?")[0];

				console.log("File size after decryption:", decryptedBlob.size);

				files.push(
					new File([decryptedBlob], fileName, {
						type: "application/octet-stream",
					})
				);
			} catch (error) {
				console.error("Error fetching or decrypting file content:", error);
				throw error;
			}
		}
		return files;
	};

	// eslint-disable-next-line
	const isFileTypeSupported = (fileName) => {
		const supportedExtensions = [".txt", ".pdf", ".docx", ".md"];
		const extension = "." + fileName.split(".").pop().toLowerCase();
		return supportedExtensions.includes(extension);
	};

	const createVectorStoreAndUploadFiles = async (fileUrls, fileData) => {
		try {
			const vectorStore = await openai.beta.vectorStores.create({
				name: "KnowledgeBaseStore",
			});

			const files = await getFileContent(fileUrls, fileData); // Pass both fileUrls and fileData

			console.log("Files prepared for upload:", files.length);

			if (files.length === 0) {
				throw new Error("No files available for upload.");
			}

			console.log("Uploading files to OpenAI...");
			const uploadResponse =
				await openai.beta.vectorStores.fileBatches.uploadAndPoll(
					vectorStore.id,
					{ files }
				);
			console.log("Upload response:", uploadResponse);

			return vectorStore.id;
		} catch (error) {
			console.error("Error creating vector store and uploading files:", error);
			if (error.response) {
				console.error("OpenAI API error:", error.response.data);
			}
			throw error;
		}
	};

	const createAssistantWithFiles = async (vectorStoreId) => {
		try {
			const combinedInstructions = `${instructions || "You are an assistant."}`;

			const assistantResponse = await openai.beta.assistants.create({
				name: customBotName || "New Assistant",
				instructions: combinedInstructions,
				model: "gpt-4o",
				tools: [{ type: "file_search" }],
				tool_resources: {
					file_search: {
						vector_store_ids: [vectorStoreId],
					},
				},
			});

			const assistantId = assistantResponse.id || assistantResponse.data?.id;
			if (!assistantId) {
				throw new Error("Failed to retrieve assistant ID from response");
			}

			console.log(
				"Assistant created and files linked. Assistant ID:",
				assistantId
			);
			return assistantId;
		} catch (error) {
			console.error("Detailed error in createAssistantWithFiles:", error);
			console.error("Error name:", error.name);
			console.error("Error message:", error.message);
			console.error("Error stack:", error.stack);
			throw error;
		}
	};

	const handleRunWorkflow = async () => {
		if (selectedFiles.length === 0) {
			alert("Please select at least one file from the knowledge base.");
			return;
		}
		if (!customBotName) {
			alert("Please enter a custom agent name.");
			return;
		}
		setLoading(true);
		setTrainingStage(1);

		try {
			const fileUrls = selectedFiles.map((file) => file.file);
			const files = selectedFiles.map((file) => file);
			const vectorStoreId = await createVectorStoreAndUploadFiles(
				fileUrls,
				files
			);
			setTrainingStage(2);
			console.log("vector StoreId", vectorStoreId);
			const assistantId = await createAssistantWithFiles(vectorStoreId);
			console.log("assistantId", assistantId);
			setTrainingStage(3);
			setAssistantId(assistantId);

			const thread = await openai.beta.threads.create({
				messages: [
					{
						role: "user",
						content: "Initial message to create the thread.",
					},
				],
			});
			setThreadId(thread.id);

			await openai.beta.threads.messages.create(thread.id, {
				role: "user",
				content: "How can I use the uploaded knowledge base?",
			});
			setTrainingStage(4);

			const collectionPath = `teams/${teamId}/assistants`;
			const isPersonal = selectedDepartments.length === 0;

			const assistantRef = await addDoc(collection(db, collectionPath), {
				name: customBotName,
				assistantId: assistantId,
				threadId: thread.id,
				createdAt: new Date(),
				knowledgeBase: fileUrls,
				type: botName,
				level: "staff",
				departments: selectedDepartments,
				isPersonal: isPersonal,
				createdBy: user.uid,
				enableImageCreation: enableImageCreation,
			});
			setTrainingStage(5);

			setNewDocID(assistantRef.id);
			setIsReady(true);
		} catch (error) {
			console.error("Error during workflow:", error);
			alert(`Error during workflow: ${error.message}`);
		}

		setLoading(false);
	};

	const handleImageCreationToggle = () => {
		setEnableImageCreation(!enableImageCreation);
	};

	return (
		<div
			className={`main-content socialmedia-content main-color-${switchChecked}`}
		>
			<div className="input-section">
				<div className="template-header">
					<h2>{botName}</h2>
					<p>
						<i>{description}</i>
					</p>
				</div>
				<div className={`card card-color-${switchChecked} bot-training-div`}>
					{trainingStage === 0 && (
						<>
							<input
								type="text"
								value={customBotName}
								onChange={(e) => setCustomBotName(e.target.value)}
								placeholder="Enter custom agent name"
								className="BotName"
								style={{ borderRadius: "10px" }}
							/>
							<div className="file-selection">
								<label>
									<strong>Select files from Knowledge Base</strong>
								</label>
								<div className="search-bar">
									<input
										className="search-knowledgebases"
										type="text"
										placeholder="Search files..."
										value={searchQuery}
										onChange={(e) => setSearchQuery(e.target.value)}
									/>
								</div>
								<div className="file-list">
									<div
										className="upload-section"
										style={{
											height: "40vh",
											overflowY: "scroll",
										}}
									>
										<h4>Personal knowledgebase</h4>
										{filteredPersonalFiles.map((file) => (
											<div
												key={file.id}
												className="card-border"
												style={{
													display: "flex",
													overflow: "hidden",
													width: "100%",
													padding: "5px",
													borderTop: "2px solid transparent",
													borderRadius: "10px",
													minHeight: "fit-content",
												}}
											>
												<input
													type="checkbox"
													id={`file-${file.id}`}
													checked={selectedFiles.some((f) => f.id === file.id)}
													onChange={() => handleFileSelection(file.id, true)}
												/>
												<label htmlFor={`file-${file.id}`}>{file.name}</label>
											</div>
										))}
									</div>
									<div className="upload-section">
										<h4>Shared knowledgebase</h4>
										{filteredSharedFiles.map((file) => (
											<div
												key={file.id}
												className="card-border"
												style={{
													display: "flex",
													overflow: "hidden",
													width: "100%",
													padding: "5px",
													borderTop: "2px solid transparent",
													borderRadius: "10px",
													minHeight: "fit-content",
												}}
											>
												<input
													type="checkbox"
													id={`file-${file.id}`}
													checked={selectedFiles.some((f) => f.id === file.id)}
													onChange={() => handleFileSelection(file.id, false)}
												/>
												<label htmlFor={`file-${file.id}`}>{file.name}</label>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="image-creation-toggle">
								<label>
									<input
										type="checkbox"
										checked={enableImageCreation}
										onChange={handleImageCreationToggle}
									/>
									Enable Image Creation
								</label>
							</div>
							<div className="buttons-thread">
								{!isReady && (
									<button
										className="workflow-button main-content-button"
										onClick={handleRunWorkflow}
										disabled={loading}
									>
										{loading ? "Training AI..." : "Generate Agent"}
									</button>
								)}
							</div>
						</>
					)}
					{trainingStage !== 0 && (
						<div className="update-message">
							<div
								className={`individual-message bot-creation-message ${
									trainingStage >= 1 ? "completed" : ""
								}`}
							>
								{trainingStage >= 1 ? (
									<FontAwesomeIcon icon={faCircleCheck} />
								) : (
									<FontAwesomeIcon icon={faSpinner} spin />
								)}
								<h5>Creating Assistant</h5>
							</div>
							<div
								className={`individual-message bot-creation-message ${
									trainingStage >= 2 ? "completed" : ""
								}`}
							>
								{trainingStage >= 2 ? (
									<FontAwesomeIcon icon={faCircleCheck} />
								) : (
									<FontAwesomeIcon icon={faSpinner} spin />
								)}
								<h5>Uploading Files</h5>
							</div>
							<div
								className={`individual-message bot-creation-message ${
									trainingStage >= 3 ? "completed" : ""
								}`}
							>
								{trainingStage >= 3 ? (
									<FontAwesomeIcon icon={faCircleCheck} />
								) : (
									<FontAwesomeIcon icon={faSpinner} spin />
								)}
								<h5>Assistant Created</h5>
							</div>
							<div
								className={`individual-message bot-creation-message ${
									trainingStage >= 4 ? "completed" : ""
								}`}
							>
								{trainingStage >= 4 ? (
									<FontAwesomeIcon icon={faCircleCheck} />
								) : (
									<FontAwesomeIcon icon={faSpinner} spin />
								)}
								<h5>Trained Assistant</h5>
							</div>
							<div
								className={`individual-message bot-creation-message ${
									trainingStage >= 5 ? "completed" : ""
								}`}
							>
								{trainingStage >= 5 ? (
									<FontAwesomeIcon icon={faCircleCheck} />
								) : (
									<FontAwesomeIcon icon={faSpinner} spin />
								)}
								<h5>Trained and ready for use</h5>
							</div>
							{isReady && (
								<div className="link-to-thread">
									<Link to={`/chat/${newDocID}`} className="workflow-button">
										Go To Thread
									</Link>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default BotTemplate;
