import React, { useState, useEffect, useCallback } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

const CustomizeSpinFlow = ({ user, switchChecked }) => {
	const [instructions, setInstructions] = useState("");
	const [description, setDescription] = useState("");
	const [instructionsWordCount, setInstructionsWordCount] = useState(0);
	const [descriptionWordCount, setDescriptionWordCount] = useState(0);

	const WORD_LIMIT = 1000;

	useEffect(() => {
		const fetchCustomInstructions = async () => {
			if (user && user.uid) {
				const userDoc = await getDoc(doc(db, `users/${user.uid}`));
				if (userDoc.exists()) {
					const userData = userDoc.data();
					setInstructions(userData.customInstructions || "");
					setDescription(userData.customDescription || "");
					setInstructionsWordCount(
						countWords(userData.customInstructions || "")
					);
					setDescriptionWordCount(countWords(userData.customDescription || ""));
				}
			}
		};

		fetchCustomInstructions();
	}, [user]);

	const countWords = (text) => {
		return text.trim().split(/\s+/).length;
	};

	const handleTextChange = (setText, setWordCount, value) => {
		const words = countWords(value);
		if (words <= WORD_LIMIT) {
			setText(value);
			setWordCount(words);
		}
	};

	const saveToFirestore = useCallback(async () => {
		if (user && user.uid) {
			try {
				await setDoc(
					doc(db, `users/${user.uid}`),
					{
						customInstructions: instructions,
						customDescription: description,
					},
					{ merge: true }
				);
				console.log("Custom instructions saved successfully!");
			} catch (error) {
				console.error("Error saving custom instructions:", error);
			}
		}
	}, [user, instructions, description]);

	const handleBlur = () => {
		saveToFirestore();
	};

	return (
		<div className={`main-content main-color-${switchChecked}`}>
			<div className="white-box-overlay team-members-container">
				<form
					className="main-form"
					style={{ height: "auto", minWidth: "100%", marginTop: "20px" }}
				>
					<div className="form-group">
						<h2>Customize Your SpinFlow</h2>
						<p style={{ color: switchChecked ? "grey" : "wheat" }}>
							Please note that changes to these settings will affect all future
							conversations.
						</p>
						<p
							style={{
								color: switchChecked ? "grey" : "wheat",
								fontSize: "0.8em",
							}}
						>
							<i>This page auto saves your changes.</i>
						</p>
					</div>
					<div className="form-group">
						<label
							htmlFor="instructions"
							style={{
								fontSize: "1em",
								display: "flex",
								justifyContent: "flex-start",
								width: "100%",
							}}
						>
							What would you like SpinFlow to know about you to provide better
							responses?
						</label>
						<textarea
							placeholder="Enter Custom Instructions"
							id="instructions"
							className="chat-textarea"
							value={instructions}
							onChange={(e) =>
								handleTextChange(
									setInstructions,
									setInstructionsWordCount,
									e.target.value
								)
							}
							onBlur={handleBlur}
						></textarea>
						<div className="word-count">
							{instructionsWordCount}/{WORD_LIMIT} words
						</div>
					</div>
					<div className="form-group">
						<label
							htmlFor="description"
							style={{
								fontSize: "1em",
								display: "flex",
								justifyContent: "flex-start",
								width: "100%",
							}}
						>
							How would you like SpinFlow to respond?
						</label>
						<textarea
							id="description"
							placeholder="How would you want the AI to respond?"
							className="chat-textarea"
							value={description}
							onChange={(e) =>
								handleTextChange(
									setDescription,
									setDescriptionWordCount,
									e.target.value
								)
							}
							onBlur={handleBlur}
						></textarea>
						<div className="word-count">
							{descriptionWordCount}/{WORD_LIMIT} words
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default CustomizeSpinFlow;
