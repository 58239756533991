import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Styles/Templates.css";

// Importing bot names and descriptions
import {
	botName as MarketingBotName,
	description as MarketingDescription,
} from "./BotData/MarketingStrategyBot";
import {
	botName as DataAnalysisBotName,
	description as DataAnalysisDescription,
} from "./BotData/DataAnalysisBot";
import {
	botName as CustomerServiceBotName,
	description as CustomerServiceDescription,
} from "./BotData/CustomerServiceBot";
import {
	botName as GeneralKnowledgeBotName,
	description as GeneralKnowledgeDescription,
} from "./BotData/AllKnowledgeBot";
import {
	botName as SocialMediaBotName,
	description as SocialMediaDescription,
} from "./BotData/SocialMediaBot";
import {
	botName as HumanResourcesBotName,
	description as HumanResourcesDescription,
} from "./BotData/HumanResourcesBot";
import {
	botName as ProjectManagementBotName,
	description as ProjectManagementDescription,
} from "./BotData/ProjectManagementBot";
import {
	botName as SupplyChainBotName,
	description as SupplyChainDescription,
} from "./BotData/SupplyChainBot";
import {
	botName as ITSupportBotName,
	description as ITSupportDescription,
} from "./BotData/ITSupportBot";
import {
	botName as LegalComplianceBotName,
	description as LegalComplianceDescription,
} from "./BotData/LegalComplianceBot";
import {
	botName as ProductDevelopmentBotName,
	description as ProductDevelopmentDescription,
} from "./BotData/ProductDevelopmentBot";
import {
	botName as SalesOptimizationBotName,
	description as SalesOptimizationDescription,
} from "./BotData/SalesOptimizationBot";
import {
	botName as BusinessIntelligenceBotName,
	description as BusinessIntelligenceDescription,
} from "./BotData/BusinessIntelligenceBot";
import {
	botName as EventPlanningBotName,
	description as EventPlanningDescription,
} from "./BotData/EventPlanningBot";
import {
	botName as TrainingDevelopmentBotName,
	description as TrainingDevelopmentDescription,
} from "./BotData/TrainingDevelopmentBot";
import {
	botName as DigitalMarketingBotName,
	description as DigitalMarketingDescription,
} from "./BotData/DigitalMarketingBot";
import {
	botName as SOPBotName,
	description as SOPDescription,
} from "./BotData/SOPBot";

// Define the bot data with categories
const botData = [
	{
		category: "Social Media",
		icon: "fas fa-share-alt", // Example icon for Social Media
		bots: [
			{
				name: SocialMediaBotName,
				description: SocialMediaDescription,
				link: "/social-media-bot",
			},
			// Add more social media bots here if needed...
		],
	},
	{
		category: "Business",
		icon: "fas fa-briefcase", // Example icon for Business
		bots: [
			{
				name: GeneralKnowledgeBotName,
				description: GeneralKnowledgeDescription,
				link: "/general-knowledge-bot",
			},
			{
				name: LegalComplianceBotName,
				description: LegalComplianceDescription,
				link: "/legal-compliance-bot",
			},
			// Add more business bots here if needed...
		],
	},
	{
		category: "HR",
		icon: "fas fa-users", // Example icon for HR
		bots: [
			{
				name: HumanResourcesBotName,
				description: HumanResourcesDescription,
				link: "/human-resources-bot",
			},
			// Add more HR bots here if needed...
		],
	},
	{
		category: "Marketing",
		icon: "fas fa-bullhorn", // Example icon for Marketing
		bots: [
			{
				name: MarketingBotName,
				description: MarketingDescription,
				link: "/market-strategy-bot",
			},
			{
				name: DigitalMarketingBotName,
				description: DigitalMarketingDescription,
				link: "/digital-marketing-bot",
			},
			// Add more marketing bots here if needed...
		],
	},
	{
		category: "Operations",
		icon: "fas fa-cogs", // Example icon for Operations
		bots: [
			{
				name: ProjectManagementBotName,
				description: ProjectManagementDescription,
				link: "/project-management-bot",
			},
			{
				name: SupplyChainBotName,
				description: SupplyChainDescription,
				link: "/supply-chain-bot",
			},
			{
				name: ITSupportBotName,
				description: ITSupportDescription,
				link: "/it-support-bot",
			},
			{
				name: SOPBotName,
				description: SOPDescription,
				link: "/sop-bot",
			},
			// Add more operations bots here if needed...
		],
	},
	{
		category: "Sales",
		icon: "fas fa-chart-line", // Example icon for Sales
		bots: [
			{
				name: SalesOptimizationBotName,
				description: SalesOptimizationDescription,
				link: "/sales-optimization-bot",
			},
			{
				name: CustomerServiceBotName,
				description: CustomerServiceDescription,
				link: "/customer-service-bot",
			},
			// Add more sales bots here if needed...
		],
	},
	{
		category: "Data Analysis",
		icon: "fas fa-chart-pie", // Example icon for Data Analysis
		bots: [
			{
				name: DataAnalysisBotName,
				description: DataAnalysisDescription,
				link: "/data-analysis-bot",
			},
			{
				name: BusinessIntelligenceBotName,
				description: BusinessIntelligenceDescription,
				link: "/business-intelligence-bot",
			},
			// Add more data analysis bots here if needed...
		],
	},
	{
		category: "Other",
		icon: "fas fa-ellipsis-h", // Example icon for Other
		bots: [
			{
				name: EventPlanningBotName,
				description: EventPlanningDescription,
				link: "/event-planning-bot",
			},
			{
				name: TrainingDevelopmentBotName,
				description: TrainingDevelopmentDescription,
				link: "/training-development-bot",
			},
			{
				name: ProductDevelopmentBotName,
				description: ProductDevelopmentDescription,
				link: "/project-development-bot",
			},
			// Add more other bots here if needed...
		],
	},
	// Add more categories and bots here as needed...
];

const Templates = ({ switchChecked }) => {
	const [selectedCategory, setSelectedCategory] = useState("All");

	const handleCategoryChange = (category) => {
		setSelectedCategory(category);
	};

	const filteredBots =
		selectedCategory === "All"
			? botData.flatMap((category) => category.bots)
			: botData.find((category) => category.category === selectedCategory)
					?.bots || [];

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<div className="template-header">
				<h2>Spin Up An Agent</h2>
				<div className={`category-buttons-${switchChecked}`}>
					<button
						onClick={() => handleCategoryChange("All")}
						className={`category-button-${switchChecked} ${
							selectedCategory === "All" ? "active" : ""
						}`}
					>
						All
					</button>
					{botData.map((category) => (
						<button
							key={category.category}
							onClick={() => handleCategoryChange(category.category)}
							className={`category-button-${switchChecked} ${
								selectedCategory === category.category ? "active" : ""
							}`}
						>
							{category.category}
						</button>
					))}
				</div>
			</div>
			<div className="template-category">
				<div className={`template-grid template-color-${switchChecked}`}>
					{/* Always show the Create Custom Bot option */}
					<Link
						to="/create-custom-bot"
						className={`template-card card-color-${switchChecked} template-flex-card card-border`}
						style={{ borderTop: "2px solid transparent" }}
					>
						<div className="template-icon">
							<i className="fas fa-bullhorn"></i>
						</div>
						<h4>Custom Agent</h4>
						<p>
							Build your own Agent, with custom instructions to perform tasks
							exactly how you want them to be done.
						</p>
						<button className="main-content-button home-button">
							Create Agent
						</button>
					</Link>

					{filteredBots.map((bot, index) => (
						<Link
							key={index}
							to={bot.link}
							className={`template-card card-color-${switchChecked} template-flex-card card-border`}
							style={{ borderTop: "2px solid transparent" }}
						>
							<div className="template-icon">
								<i className="fas fa-bullhorn"></i>
							</div>
							<h4>{bot.name}</h4>
							<p>{bot.description}</p>
							<button className="main-content-button home-button">
								Use Pro Agent
							</button>
						</Link>
					))}

					<div
						className="bottom-spacer"
						style={{ backgroundColor: "transparent !important" }}
					></div>
				</div>
			</div>
		</div>
	);
};

export default Templates;
