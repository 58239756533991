import React, { useState } from "react";

const Credentials = ({ user, switchChecked }) => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [zapierData, setZapierData] = useState(null); // State to store received data

	// Function to send data to backend, which forwards it to Zapier
	const sendToZapier = async (data) => {
		const serverURL = "http://localhost:4000/api/data"; // Backend webhook URL

		try {
			const response = await fetch(serverURL, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});

			if (response.ok) {
				console.log("Data sent successfully");
			} else {
				console.error("Failed to send data");
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	// Handle form submission
	const handleSubmit = (event) => {
		event.preventDefault();

		const formData = {
			name: name,
			email: email,
		};

		// Send form data to the backend
		sendToZapier(formData);

		// Reset form fields
		setName("");
		setEmail("");
	};

	// Function to fetch data from backend that Zapier sends via webhook
	const fetchZapierData = async () => {
		try {
			const response = await fetch("http://localhost:4000/api/getData"); // API to get Zapier data
			const data = await response.json();
			setZapierData(data);
		} catch (error) {
			console.error("Error fetching data from backend:", error);
		}
	};

	// Poll backend every 5 seconds to check for new Zapier data
	// useEffect(() => {
	//     const interval = setInterval(fetchZapierData, 5000); // Poll every 5 seconds
	//     return () => clearInterval(interval); // Cleanup interval on component unmount
	// }, []);

	return (
		<div className={`main-content main-color-${switchChecked}`}>
			<h2>Submit Info</h2>
			<form onSubmit={handleSubmit}>
				<div>
					<label>
						Name:
						<input
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
							className="flow-name main-flows-name"
						/>
					</label>
				</div>
				<div>
					<label>
						Email:
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							className="flow-name main-flows-name"
						/>
					</label>
				</div>
				<div>
					<button type="submit" className="main-content-button">
						Submit
					</button>
				</div>
			</form>

			<div style={{ marginTop: "20px" }}>
				<h2>Data from Zapier</h2>
				<button onClick={fetchZapierData} className="main-content-button">
					Fetch Data from Zapier
				</button>
				{zapierData ? (
					<pre>{JSON.stringify(zapierData, null, 2)}</pre>
				) : (
					<p>No data received from Zapier yet.</p>
				)}
			</div>
		</div>
	);
};

export default Credentials;
