import React, { useState, useEffect, useCallback } from "react";
import { db, storage } from "../../firebaseConfig";
import axios from "axios";
import {
	collection,
	addDoc,
	getDocs,
	query,
	doc,
	getDoc,
	orderBy,
	limit,
	where,
	updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, getBlob } from "firebase/storage";
import "./Styles/KnowledgeBase.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileArrowUp,
	faMicroscope,
	faMicrophoneLines,
	faBroom,
	faUser,
	faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import OpenAI from "openai";

const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

const KnowledgeBase = ({ user, switchChecked }) => {
	// eslint-disable-next-line
	const [files, setFiles] = useState([]);
	const [fileNames, setFileNames] = useState([]);
	const [url, setUrl] = useState("");
	const [isDragActive, setIsDragActive] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [scrapedFiles, setScrapedFiles] = useState([]);
	const [voiceFiles, setVoiceFiles] = useState([]);
	const [interviewFiles, setInterviewFiles] = useState([]);
	// eslint-disable-next-line
	const [updateMessage, setUpdateMessage] = useState(0);
	const [scrapeButtonClicked, setScrapeButtonClicked] = useState(false);
	// eslint-disable-next-line
	const [teamId, setTeamId] = useState(null);
	// eslint-disable-next-line
	const [assistant, setAssistant] = useState(null);
	const [vectorStoreId, setVectorStoreId] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userDoc = await getDoc(doc(db, `users/${user.uid}`));
				if (userDoc.exists()) {
					const userData = userDoc.data();
					setTeamId(userData.teamId);
				}
			} catch (error) {
				console.error("Error fetching user data: ", error);
			}
		};
		fetchUserData();
	}, [user.uid]);

	useEffect(() => {
		if (user) {
			initializeAssistant();
		}
		// eslint-disable-next-line
	}, [user]);

	const initializeAssistant = async () => {
		try {
			const userDocRef = doc(db, `users/${user.uid}`);
			const userDoc = await getDoc(userDocRef);

			if (userDoc.exists() && userDoc.data().assistantId) {
				setAssistant(userDoc.data().assistantId);
				setVectorStoreId(userDoc.data().vectorStoreId);
			} else {
				const vectorStore = await openai.beta.vectorStores.create({
					name: `${user.uid}_KnowledgeStore`,
				});

				const newAssistant = await openai.beta.assistants.create({
					name: "Knowledge Base Assistant",
					instructions:
						"You are an assistant with access to the user's knowledge base.",
					model: "gpt-4o",
					tools: [{ type: "file_search" }],
					tool_resources: {
						file_search: {
							vector_store_ids: [vectorStore.id],
						},
					},
				});

				await updateDoc(userDocRef, {
					assistantId: newAssistant.id,
					vectorStoreId: vectorStore.id,
				});

				setAssistant(newAssistant.id);
				setVectorStoreId(vectorStore.id);
			}
		} catch (error) {
			console.error("Error initializing assistant:", error);
		}
	};

	const decryptFile = async (arrayBuffer, keyBuffer, ivBuffer) => {
		console.log(
			"Key (hex):",
			Array.from(keyBuffer)
				.map((b) => b.toString(16).padStart(2, "0"))
				.join(" ")
		);
		console.log(
			"IV (hex):",
			Array.from(ivBuffer)
				.map((b) => b.toString(16).padStart(2, "0"))
				.join(" ")
		);

		const algorithm = { name: "AES-CBC", iv: ivBuffer };
		try {
			const key = await crypto.subtle.importKey(
				"raw",
				keyBuffer,
				algorithm,
				false,
				["decrypt"]
			);
			const decrypted = await crypto.subtle.decrypt(
				algorithm,
				key,
				arrayBuffer
			);
			console.log("Decryption successful, length:", decrypted.byteLength);
			return new Uint8Array(decrypted);
		} catch (error) {
			console.error("Decryption failed:", error);
			throw error;
		}
	};

	const decryptFileGCM = async (arrayBuffer, keyBuffer, ivBuffer) => {
		console.log(
			"Key (hex):",
			Array.from(keyBuffer)
				.map((b) => b.toString(16).padStart(2, "0"))
				.join(" ")
		);
		console.log(
			"IV (hex):",
			Array.from(ivBuffer)
				.map((b) => b.toString(16).padStart(2, "0"))
				.join(" ")
		);

		const algorithm = { name: "AES-GCM", iv: ivBuffer };
		try {
			const key = await crypto.subtle.importKey(
				"raw",
				keyBuffer,
				algorithm,
				false,
				["decrypt"]
			);
			const decrypted = await crypto.subtle.decrypt(
				algorithm,
				key,
				arrayBuffer
			);
			console.log("Decryption successful, length:", decrypted.byteLength);
			return new Uint8Array(decrypted);
		} catch (error) {
			console.error("Decryption failed:", error);
			throw error;
		}
	};

	const getFileContent = async (fileUrl, fileData) => {
		try {
			const fileRef = ref(storage, fileUrl);
			const blob = await getBlob(fileRef);
			const arrayBuffer = await blob.arrayBuffer();

			console.log("File size before decryption:", arrayBuffer.byteLength);

			const keyBuffer = new Uint8Array(fileData[0].key);
			const ivBuffer = new Uint8Array(fileData[0].iv);

			if (keyBuffer.length !== 16 && keyBuffer.length !== 32) {
				throw new Error(
					"Invalid key length. AES key must be either 128 bits (16 bytes) or 256 bits (32 bytes)."
				);
			}

			let decryptedData;
			try {
				decryptedData = await decryptFile(arrayBuffer, keyBuffer, ivBuffer);
			} catch (cbcError) {
				console.log("CBC decryption failed, attempting GCM...");
				try {
					decryptedData = await decryptFileGCM(
						arrayBuffer,
						keyBuffer,
						ivBuffer
					);
				} catch (gcmError) {
					throw new Error("Both CBC and GCM decryption failed");
				}
			}

			console.log("Decrypted data length:", decryptedData.length);

			if (decryptedData.length === 0) {
				throw new Error("Decrypted file is empty");
			}

			const decryptedBlob = new Blob([decryptedData], {
				type: "application/octet-stream",
			});
			let fileName = fileUrl.split("/").pop();
			fileName = fileName.split("?")[0];

			console.log("File size after decryption:", decryptedBlob.size);

			return new File([decryptedBlob], fileName, {
				type: "application/octet-stream",
			});
		} catch (error) {
			console.error("Error fetching or decrypting file content:", error);
			throw error;
		}
	};

	const updateVectorStore = async (file) => {
		try {
			if (!file || !file.name) {
				console.error("Invalid file object:", file);
				return;
			}

			const supportedExtensions = [".txt", ".pdf", ".doc", ".docx", ".csv"];
			const fileExtension = "." + file.name.split(".").pop().toLowerCase();

			if (!supportedExtensions.includes(fileExtension)) {
				console.warn(
					`File type ${fileExtension} is not supported for vector store. Skipping vector store update.`
				);
				return;
			}

			// Fetch and decrypt the file content
			const decryptedFile = await getFileContent(file.file, [
				{ key: file.key, iv: file.iv },
			]);

			console.log(
				"Decrypted file prepared for upload:",
				decryptedFile.name,
				decryptedFile.size
			);

			const fileName = decryptedFile.name.includes(".")
				? decryptedFile.name
				: `${decryptedFile.name}.txt`;

			await openai.beta.vectorStores.fileBatches.uploadAndPoll(vectorStoreId, {
				files: [new File([decryptedFile], fileName)],
			});

			console.log("Vector store updated successfully");
		} catch (error) {
			console.error("Error updating vector store:", error);
			if (error.status === 400) {
				console.error(
					"Bad request error. Please check the file format and try again."
				);
			}
			throw error;
		}
	};

	const updateVectorStoreWithRetry = async (file, maxRetries = 3) => {
		for (let i = 0; i < maxRetries; i++) {
			try {
				await updateVectorStore(file);
				return; // Success, exit the function
			} catch (error) {
				console.error(`Attempt ${i + 1} failed:`, error);
				if (i === maxRetries - 1) throw error; // Throw on last attempt
				await new Promise((resolve) => setTimeout(resolve, 1000 * (i + 1))); // Wait before retrying
			}
		}
	};

	const fetchFiles = useCallback(
		async (formType, setFilesCallback) => {
			try {
				const q = query(
					collection(db, `users/${user.uid}/knowledgebase`),
					where("form", "==", formType),
					orderBy("createdAt", "desc"),
					limit(10)
				);
				const querySnapshot = await getDocs(q);
				const filesList = [];
				querySnapshot.forEach((doc) => {
					filesList.push({ id: doc.id, ...doc.data() });
				});
				setFilesCallback(filesList);
			} catch (error) {
				console.error("Error fetching files: ", error);
			}
		},
		[user.uid]
	);

	useEffect(() => {
		fetchFiles("File Upload", setUploadedFiles);
		fetchFiles("scrape", setScrapedFiles);
		fetchFiles("Voice Dump", setVoiceFiles);
		fetchFiles("Interview", setInterviewFiles);
	}, [fetchFiles]);

	const handleFileChange = async (e) => {
		const selectedFiles = Array.from(e.target.files);
		setFiles(selectedFiles);
		setFileNames(selectedFiles.map((file) => file.name));
		await handleUpload(selectedFiles);
	};

	const encryptFile = async (fileBuffer, key, iv) => {
		const algorithm = { name: "AES-GCM", iv: iv };
		const encrypted = await crypto.subtle.encrypt(algorithm, key, fileBuffer);
		return new Uint8Array(encrypted);
	};

	const generateAESKey = async (length = 256) => {
		return crypto.subtle.generateKey(
			{
				name: "AES-GCM",
				length: length,
			},
			true,
			["encrypt", "decrypt"]
		);
	};

	const uploadToFirebase = async (file) => {
		const storageRef = ref(storage, `users/${user.uid}/personal/${file.name}`);
		const dbCollection = collection(db, `users/${user.uid}/knowledgebase`);

		const fileBuffer = await file.arrayBuffer();
		const aesKey = await generateAESKey(256);
		const keyBuffer = await crypto.subtle.exportKey("raw", aesKey);
		const iv = crypto.getRandomValues(new Uint8Array(16));
		const encryptedFileData = await encryptFile(fileBuffer, aesKey, iv);
		const encryptedBlob = new Blob([encryptedFileData], { type: file.type });

		const uploadTask = uploadBytes(storageRef, encryptedBlob);
		const uploadResult = await uploadTask;
		const downloadURL = await getDownloadURL(uploadResult.ref);

		const newFile = {
			name: file.name,
			file: downloadURL,
			iv: Array.from(iv),
			key: Array.from(new Uint8Array(keyBuffer)),
			createdAt: new Date(),
			uploadedBy: user.uid,
			isTeamFile: false,
			form: "File Upload",
		};

		const docRef = await addDoc(dbCollection, newFile);
		return { ...newFile, id: docRef.id };
	};

	const handleUpload = async (selectedFiles) => {
		if (selectedFiles.length === 0) return;

		setIsUploading(true);
		const uploadPromises = [];
		const uploadedFiles = [];

		try {
			for (const file of selectedFiles) {
				const uploadPromise = uploadToFirebase(file).then((uploadedFile) => {
					uploadedFiles.push(uploadedFile);
				});
				uploadPromises.push(uploadPromise);
			}

			await Promise.all(uploadPromises);

			setFiles([]);
			setFileNames([]);
			fetchFiles("File Upload", setUploadedFiles);
			alert("Files uploaded successfully!");

			// Start vector store update in the background
			updateVectorStoreForUploadedFiles(uploadedFiles);
		} catch (error) {
			console.error("Error uploading files: ", error);
			alert("Error uploading files. Please try again.");
		} finally {
			setIsUploading(false);
		}
	};

	const updateVectorStoreForUploadedFiles = async (uploadedFiles) => {
		for (const file of uploadedFiles) {
			try {
				await updateVectorStoreWithRetry(file);
				console.log(`Vector store updated for file: ${file.name}`);
			} catch (error) {
				console.error(
					`Error updating vector store for file ${file.name}:`,
					error
				);
			}
		}
		console.log("Vector store update completed for all files");
	};

	const handleScrape = async () => {
		setUpdateMessage(1);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_SERVER_URL}/scrapeAndStore`,
				{ url },
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			setUpdateMessage(2);

			const scrapedData = response.data;
			await new Promise((resolve) => setTimeout(resolve, 3000));

			setUpdateMessage(3);
			const scrapedText = scrapedData
				.map((item) => `URL: ${item.url}\nTEXT: ${item.text}`)
				.join("\n\n");
			const blob = new Blob([scrapedText], { type: "text/plain" });
			const fileName = `${url}-Scraped.txt`;

			const storageRef = ref(storage, `users/${user.uid}/personal/${fileName}`);
			await uploadBytes(storageRef, blob);
			const downloadURL = await getDownloadURL(storageRef);

			const dbCollection = collection(db, `users/${user.uid}/knowledgebase`);

			const newFile = await addDoc(dbCollection, {
				name: fileName,
				file: downloadURL,
				createdAt: new Date(),
				uploadedBy: user.uid,
				isTeamFile: false,
				form: "scrape",
			});

			await updateVectorStoreWithRetry(newFile);

			setUpdateMessage(4);

			fetchFiles("scrape", setScrapedFiles);
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setUpdateMessage(0);
			alert("Scraping completed and file added to knowledge base.");
		} catch (error) {
			console.error("Error scraping the URL:", error);
			setUpdateMessage(0);
			if (error.response) {
				alert(
					`Error scraping the URL: ${
						error.response.data.error || error.response.statusText
					}`
				);
			} else if (error.request) {
				alert(
					"Error scraping the URL: No response received from server. Please check your network connection."
				);
			} else {
				alert(`Error scraping the URL: ${error.message}`);
			}
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		setIsDragActive(true);
	};

	const handleDrop = async (e) => {
		e.preventDefault();
		setIsDragActive(false);
		const droppedFiles = Array.from(e.dataTransfer.files);
		if (droppedFiles.length > 0) {
			setFiles(droppedFiles);
			setFileNames(droppedFiles.map((file) => file.name));
			await handleUpload(droppedFiles);
		}
	};

	const handleDragLeave = () => {
		setIsDragActive(false);
	};

	return (
		<div
			className={`main-content main-color-${switchChecked}`}
			onDragOver={handleDragOver}
			onDrop={handleDrop}
			onDragLeave={handleDragLeave}
		>
			<h2>Add Knowledge</h2>
			<div className={`knowledge-content ${isDragActive ? "drag-active" : ""}`}>
				<div className="import-sections">
					{/* File Upload Section */}
					<div className="upload-section">
						<h3>File Upload</h3>
						<input
							type="file"
							id="fileupload"
							className="fileupload"
							onChange={handleFileChange}
							multiple
							accept=".txt,.pdf,.doc,.docx,.csv"
						/>
						{!fileNames.length && (
							<label
								htmlFor="fileupload"
								className={`file-upload-label file-color-${switchChecked}`}
							>
								<FontAwesomeIcon icon={faFileArrowUp} /> Upload File(s)
							</label>
						)}
						{fileNames.length > 0 && (
							<div className="file-name">
								Selected files: {fileNames.join(", ")}
							</div>
						)}
						<div
							className="recentFiles"
							onClick={() => navigate("/all-knowledge-base")}
						>
							<h4>Recents</h4>
							<ul>
								{uploadedFiles.map((file) => (
									<li key={file.id}>{file.name}</li>
								))}
							</ul>
						</div>
					</div>

					{/* Scrape Section */}
					<div className="scrape-section">
						<h3>Scrape Website</h3>
						{!scrapeButtonClicked ? (
							<div onClick={() => setScrapeButtonClicked(!scrapeButtonClicked)}>
								<button className="scrape-button">
									<FontAwesomeIcon icon={faBroom} /> Scrape Website
								</button>
							</div>
						) : (
							<div>
								<p>
									Please note that some websites are not able to be scraped due
									to legal restrictions
								</p>
								<input
									type="text"
									value={url}
									onChange={(e) => setUrl(e.target.value)}
									placeholder="Enter URL to scrape"
								/>
								<button onClick={handleScrape} className="scrape-button">
									<FontAwesomeIcon icon={faBroom} /> Scrape
								</button>
							</div>
						)}
						<div
							className="recentFiles"
							onClick={() => navigate("/all-knowledge-base")}
						>
							<h4>Recents</h4>
							<ul>
								{scrapedFiles.map((file) => (
									<li key={file.id}>{file.name}</li>
								))}
							</ul>
						</div>
					</div>

					{/* Voice Drop Section */}
					<div className="voice-drop-section">
						<h3>Voice Drop</h3>
						<button
							onClick={() => navigate("/voice-knowledge-base")}
							className="scrape-button"
						>
							<FontAwesomeIcon icon={faMicrophoneLines} /> Voice Drop
						</button>
						<div
							className="recentFiles"
							onClick={() => navigate("/all-knowledge-base")}
						>
							<h4>Recents</h4>
							<ul>
								{voiceFiles.map((file) => (
									<li key={file.id}>{file.name}</li>
								))}
							</ul>
						</div>
					</div>

					{/* Interview Section */}
					<div className="interview-section">
						<h3>Start Interview</h3>
						<button
							onClick={() => navigate("/interview")}
							className="scrape-button"
						>
							<FontAwesomeIcon icon={faMicroscope} /> Start Interview
						</button>
						<div
							className="recentFiles"
							onClick={() => navigate("/all-knowledge-base")}
						>
							<h4>Recents</h4>
							<ul>
								{interviewFiles.map((file) => (
									<li key={file.id}>{file.name}</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<h2>View KnowledgeBases</h2>
			<div className="knowledge-content">
				<div className="import-sections">
					<div className="knowledgebase-section">
						<div>
							<button onClick={() => navigate("/all-knowledge-base")}>
								<FontAwesomeIcon icon={faUser} /> My KnowledgeBase
							</button>
						</div>
						<div>
							<button onClick={() => navigate("/shared-knowledge-base")}>
								<FontAwesomeIcon icon={faShareNodes} /> Shared KnowledgeBases
							</button>
						</div>
					</div>
				</div>
			</div>
			{isUploading && <div>Uploading files and updating knowledge base...</div>}
		</div>
	);
};

export default KnowledgeBase;
