import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import "../Styles/WelcomeTutorial.css";

const WelcomeTutorial = ({ onClose, currentStep, setCurrentStep }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const navigateToKnowledgeBase = useCallback(
		() => navigate("/knowledge-base"),
		[navigate]
	);
	const navigateToCreateCustomBot = useCallback(
		() => navigate("/create-custom-bot"),
		[navigate]
	);
	const navigateToWorkflows = useCallback(
		() => navigate("/users/:userId/workflows"),
		[navigate]
	);
	const navigateToDepartmentsMain = useCallback(
		() => navigate("/departments-main"),
		[navigate]
	);

	const steps = useMemo(
		() => [
			{
				text: "Welcome to SpinFlow! Let's take a quick tour to get a lay of the land!",
				element: ".welcome-tutorial-content",
			},
			{
				text: "In the sidebar you have access to all SpinFlow resources.",
				element: ".sidebar",
			},
			{
				text: "Let's go to the Knowledge Center!",
				action: navigateToKnowledgeBase,
				element: ".knowledgebase-nav-section",
			},
			{
				text: "This is your Knowledge Center. You can add and manage your knowledge here. The knowledge added here can be used later on for training your own agents!",
				element: ".knowledge-content",
				page: "/knowledge-base",
			},
			{
				text: "You can drag and drop or click here to upload files to the Knowledge Center.",
				element: ".upload-section",
				page: "/knowledge-base",
			},
			{
				text: "You can also scrape websites to add content to your Knowledge Center.",
				element: ".scrape-section",
				page: "/knowledge-base",
			},
			{
				text: "Have a quick note or thought but don't want to type it out? Here you can use the cutting edge text to speech technologies to upload knowledge on the go!.",
				element: ".voice-drop-section",
				page: "/knowledge-base",
			},
			{
				text: "Don't know where to start? In this interview you will be asked a series of questions regrading your current position which will then be added to the knowledge center to help perfect your agents",
				element: ".interview-section",
				page: "/knowledge-base",
			},
			{
				text: "Now, let's check out your AI Agents.",
				action: navigateToCreateCustomBot,
				element: ".agents-nav-section",
			},
			{
				text: "Here you can create and manage your AI Agents. With three special ways of tailoring the Agent to your specific needs",
				element: ".creation-options-1",
				page: "/create-custom-bot",
			},
			{
				text: "Here you can veiw your previously created Agents and your shared Agents",
				element: ".creation-options-2",
				page: "/create-custom-bot",
			},
			{
				text: "Next, let's look at the Flows.",
				action: navigateToWorkflows,
				element: ".flows-nav-section",
			},
			{
				text: "This is where you can create and manage your Flows. To preform tedious tasks in a matter of minutes.",
				element: ".creation-options-flow-1",
				page: "/users/:userId/workflows",
			},
			{
				text: "Now, Let's go to Departments.",
				element: ".departments-nav-section",
				action: navigateToDepartmentsMain,
			},
			{
				text: "Here you can create new departments and view the stats of individual departments. Departments can be be used to handle organizing flows, agents and knowledge bases.",
				element: ".department-input",
				page: "/departments-main",
			},
			{
				text: "Finally, under the user settings you can Add New Members, Customize how your AI acts and replies to you, view your token count.",
				element: ".user",
				page: "/",
			},
			{
				text: "That's it! You're ready to start using Spinning up Agents!",
				element: null,
			},
		],
		[
			navigateToKnowledgeBase,
			navigateToCreateCustomBot,
			navigateToWorkflows,
			navigateToDepartmentsMain,
		]
	);

	const [highlightPosition, setHighlightPosition] = useState({});

	const handleStepChange = useCallback(() => {
		const currentStepData = steps[currentStep];
		if (currentStepData.page && location.pathname !== currentStepData.page) {
			navigate(currentStepData.page);
		}
		if (currentStepData.element) {
			const element = document.querySelector(currentStepData.element);
			if (element) {
				const rect = element.getBoundingClientRect();
				setHighlightPosition({
					top: rect.top + window.scrollY - 7,
					left: rect.left + window.scrollX - 7,
					width: rect.width,
					height: rect.height,
				});
				element.scrollIntoView({ behavior: "smooth", block: "center" });
			}
		} else {
			setHighlightPosition({});
		}
	}, [currentStep, location.pathname, navigate, steps]);

	useEffect(() => {
		handleStepChange();
	}, [handleStepChange]);

	const handleNext = () => {
		if (currentStep < steps.length - 1) {
			if (steps[currentStep].action) {
				steps[currentStep].action();
			}
			setCurrentStep(currentStep + 1);
		} else {
			onClose();
		}
	};

	const handleSkip = () => {
		onClose();
	};

	return (
		<div className="welcome-tutorial-overlay">
			{Object.keys(highlightPosition).length > 0 && (
				<div
					className="highlight"
					style={{
						top: highlightPosition.top,
						left: highlightPosition.left,
						width: highlightPosition.width,
						height: highlightPosition.height,
					}}
				/>
			)}
			<div className="welcome-tutorial-content">
				<p>{steps[currentStep].text}</p>
				<div className="tutorial-buttons">
					{currentStep < steps.length - 1 ? (
						<>
							<button onClick={handleSkip}>Skip Tutorial</button>
							<button onClick={handleNext}>
								Next <FontAwesomeIcon icon={faArrowRight} />
							</button>
						</>
					) : (
						<>
							<button style={{ visibility: "hidden" }}></button>
							<button onClick={handleNext}>
								Finish <FontAwesomeIcon icon={faCheck} />
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default WelcomeTutorial;
