import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebaseConfig";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import "../Styles/Header.css";

const DepartmentDropdown = ({ departments, value, onChange }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSelect = (department) => {
		onChange(department);
		setIsOpen(false);
	};

	// Add an "All" option to the departments list
	const allDepartments = [
		{ id: "all", name: "All Departments", color: "#FFFFFF" },
		...departments,
	];

	return (
		<div className="color-dropdown headerDropdown" ref={dropdownRef}>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className="color-dropdown-toggle header-department-dropdown"
			>
				{value ? (
					<>
						<span
							className="color-sample"
							style={{ backgroundColor: value.color }}
						></span>
						{value.name}
					</>
				) : (
					"Department Filter"
				)}
			</button>

			{isOpen && (
				<ul className="color-dropdown-menu header-color-dropdown-menu">
					{allDepartments.map((dept) => (
						<li
							key={dept.id}
							onClick={() => handleSelect(dept)}
							className="dropdown-item"
						>
							<span
								className="color-sample"
								style={{ backgroundColor: dept.color }}
							></span>
							{dept.name}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

const Header = ({
	user,
	switchChecked,
	selectedDepartment,
	setSelectedDepartment,
}) => {
	const [departments, setDepartments] = useState([]);
	const unsubscribeRef = useRef(null);

	useEffect(() => {
		const fetchUserAndListenToDepartments = async () => {
			if (!user?.uid) return;

			try {
				const userDoc = await getDoc(doc(db, `users/${user.uid}`));
				if (userDoc.exists()) {
					const teamId = userDoc.data().teamId;

					// Set up real-time listener for departments
					const departmentsRef = collection(db, `teams/${teamId}/departments`);
					const unsubscribe = onSnapshot(
						departmentsRef,
						(snapshot) => {
							const updatedDepartments = snapshot.docs.map((doc) => ({
								id: doc.id,
								...doc.data(),
							}));
							setDepartments(updatedDepartments);
						},
						(error) => {
							console.error("Error listening to departments: ", error);
						}
					);

					// Store the unsubscribe function
					unsubscribeRef.current = unsubscribe;
				}
			} catch (error) {
				console.error("Error fetching user or setting up listener: ", error);
			}
		};

		fetchUserAndListenToDepartments();

		// Cleanup function to unsubscribe from the listener when the component unmounts
		return () => {
			if (unsubscribeRef.current) {
				unsubscribeRef.current();
			}
		};
	}, [user]);

	return (
		<div
			className={`header main-color-${switchChecked} header-color-${switchChecked}`}
		>
			<div className="title">
				<Link to="/" className="title-link">
					<img
						src={`${switchChecked ? "/darkLogo512.png" : "/newLogo512.png"}`}
						alt="logo"
						className="mainlogo"
					/>
				</Link>
			</div>

			<div>
				{departments.length > 0 && (
					<DepartmentDropdown
						departments={departments}
						value={selectedDepartment}
						onChange={setSelectedDepartment}
					/>
				)}
			</div>
		</div>
	);
};

export default Header;
