import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, addDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./Styles/WorkflowList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPerson,
	faUserGroup,
	faUserTie,
	faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";

const WorkflowList = ({ user, switchChecked }) => {
	// eslint-disable-next-line
	const [workflows, setWorkflows] = useState([]);
	// eslint-disable-next-line
	const [templates, setTemplates] = useState([]);
	// eslint-disable-next-line
	const [teamId, setTeamId] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		const loadWorkflows = async () => {
			try {
				// First, get the user's team ID
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);

				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					const teamId = userData.teamId;
					setTeamId(teamId);

					if (teamId) {
						const querySnapshot = await getDocs(
							collection(db, `teams/${teamId}/flows`)
						);
						const loadedWorkflows = [];
						querySnapshot.forEach((doc) => {
							loadedWorkflows.push({ id: doc.id, ...doc.data() });
						});
						setWorkflows(loadedWorkflows);
					} else {
						console.error("User is not part of a team");
					}
				} else {
					console.error("User document not found");
				}
			} catch (error) {
				console.error("Error loading workflows: ", error);
			}
		};

		// Load templates from predefined configurations
		loadWorkflows();
	}, [user.uid]);

	const handleCreateNewWorkflow = async (template = null) => {
		try {
			if (!teamId) {
				console.error("User is not part of a team");
				return;
			}
			const newWorkflowData = template || {
				prompts: [],
				title: "Untitled Workflow",
				createdBy: user.uid,
				createdAt: new Date(),
				isPersonal: true,
				departments: "",
			};
			const newWorkflowRef = await addDoc(
				collection(db, `teams/${teamId}/flows`),
				newWorkflowData
			);
			navigate(`/users/${user.uid}/workflows/${newWorkflowRef.id}`);
		} catch (error) {
			console.error("Error creating new workflow: ", error);
		}
	};

	const handleProTemplate = () => {
		navigate(`/pro-flows`);
	};

	const handleViewPersonalFlows = () => {
		navigate(`/my-flows`);
	};

	const handleViewSharedFlows = () => {
		navigate(`/shared-flows`);
	};

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<h2>Create a new Flow</h2>
			<div className="creation-options creation-options-flow-1">
				<button onClick={handleProTemplate} className="main-content-button">
					<FontAwesomeIcon icon={faUserTie} />
					Use Pro Flow
				</button>
				<button
					onClick={() => handleCreateNewWorkflow()}
					className="main-content-button"
				>
					<FontAwesomeIcon icon={faPencilAlt} />
					Create Custom Flow
				</button>
			</div>

			<h2 style={{ marginTop: "10vh" }}>View Existing Flows</h2>
			<div className="creation-options">
				<button
					onClick={() => handleViewPersonalFlows()}
					className="main-content-button"
				>
					<FontAwesomeIcon icon={faPerson} />
					My Flows
				</button>
				<button
					onClick={() => handleViewSharedFlows()}
					className="main-content-button"
				>
					<FontAwesomeIcon icon={faUserGroup} />
					Shared Flows
				</button>
			</div>
		</div>
	);
};

export default WorkflowList;
