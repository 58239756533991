import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebaseConfig";
import {
	collection,
	addDoc,
	serverTimestamp,
	getDocs,
	query,
	where,
	updateDoc,
	doc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./Styles/VoiceUpload.css";
import { FaRegCirclePlay, FaRegCircleStop } from "react-icons/fa6";
import { FaRegSave } from "react-icons/fa";

const VoiceUpload = ({ user, switchChecked }) => {
	const [transcript, setTranscript] = useState("");
	const [listening, setListening] = useState(false);
	const [fileName, setFileName] = useState("");
	const [showFileNameModal, setShowFileNameModal] = useState(false);
	const [existingFiles, setExistingFiles] = useState([]);
	const [isAppending, setIsAppending] = useState(false);
	const recognitionRef = useRef(null);
	const audioContextRef = useRef(null);
	const analyserRef = useRef(null);
	const logoRef = useRef(null);

	useEffect(() => {
		const SpeechRecognition =
			window.SpeechRecognition || window.webkitSpeechRecognition;
		const recognitionInstance = new SpeechRecognition();
		recognitionInstance.continuous = true;
		recognitionInstance.interimResults = true;
		recognitionInstance.lang = "en-US";

		recognitionInstance.onresult = (event) => {
			let interimTranscript = "";
			let finalTranscript = "";

			for (let i = 0; i < event.results.length; i++) {
				const transcriptSegment = event.results[i][0].transcript;
				if (event.results[i].isFinal) {
					finalTranscript += transcriptSegment;
				} else {
					interimTranscript += transcriptSegment;
				}
			}
			setTranscript(finalTranscript + interimTranscript);
		};

		recognitionRef.current = recognitionInstance;

		const fetchExistingFiles = async () => {
			const q = query(
				collection(db, `users/${user.uid}/knowledgebase`),
				where("form", "==", "Voice Dump")
			);
			const querySnapshot = await getDocs(q);
			setExistingFiles(
				querySnapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }))
			);
		};
		fetchExistingFiles();
	}, [user.uid]);

	useEffect(() => {
		if (listening) {
			const audioContext = new (window.AudioContext ||
				window.webkitAudioContext)();
			audioContextRef.current = audioContext;
			const analyser = audioContext.createAnalyser();
			analyser.fftSize = 256;
			analyserRef.current = analyser;

			navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then((stream) => {
					const source = audioContext.createMediaStreamSource(stream);
					source.connect(analyser);
					visualize();
				})
				.catch((err) => {
					console.error("Error accessing microphone:", err);
				});
		} else {
			if (audioContextRef.current) {
				if (audioContextRef.current.state !== "closed") {
					audioContextRef.current
						.close()
						.then(() => {
							console.log("AudioContext closed");
						})
						.catch((err) => {
							console.error("Error closing AudioContext:", err);
						});
				}
			}
		}
	}, [listening]);

	const visualize = () => {
		const bufferLength = analyserRef.current.frequencyBinCount;
		const dataArray = new Uint8Array(bufferLength);

		const draw = () => {
			analyserRef.current.getByteFrequencyData(dataArray);

			let averageVolume = dataArray.reduce((a, b) => a + b) / bufferLength;
			if (logoRef.current) {
				const scale = Math.min(2, 1 + averageVolume / 100);
				logoRef.current.style.transform = `scale(${scale})`;
			}

			requestAnimationFrame(draw);
		};
		draw();
	};

	const startListening = () => {
		if (recognitionRef.current) {
			setListening(true);
			recognitionRef.current.onend = () => {
				if (listening) {
					recognitionRef.current.start();
				}
			};
			recognitionRef.current.start();
		}
	};

	const stopListening = () => {
		if (recognitionRef.current) {
			setListening(false);
			recognitionRef.current.onend = null;
			recognitionRef.current.stop();
		}
	};

	// Define encryptFile function
	const encryptFile = async (fileBuffer, key, iv) => {
		const algorithm = { name: "AES-GCM", iv: iv };
		const encrypted = await crypto.subtle.encrypt(algorithm, key, fileBuffer);
		return new Uint8Array(encrypted);
	};

	// Define generateAESKey function
	const generateAESKey = async (length = 256) => {
		return crypto.subtle.generateKey(
			{
				name: "AES-GCM",
				length: length,
			},
			true,
			["encrypt", "decrypt"]
		);
	};

	const decryptFile = async (encryptedContent, key, iv) => {
		// Ensure IV is in the correct format (Uint8Array)
		const ivArray = new Uint8Array(iv);
		const decryptedContent = await crypto.subtle.decrypt(
			{ name: "AES-GCM", iv: ivArray }, // Use the ivArray
			key,
			encryptedContent
		);
		return new TextDecoder().decode(decryptedContent);
	};

	const saveTextFile = async (text, fileName, append = false) => {
		console.log("file existing content", text);
		const storage = getStorage();
		const storageRef = ref(
			storage,
			`users/${user.uid}/knowledgebase/${fileName}`
		);

		try {
			let existingContent = "";
			let key, iv;

			if (append) {
				const existingFile = existingFiles.find(
					(file) => file.name === fileName
				);
				if (existingFile) {
					const fileDoc = await getDocs(
						query(
							collection(db, `users/${user.uid}/knowledgebase`),
							where("name", "==", fileName)
						)
					);
					if (!fileDoc.empty) {
						const fileData = fileDoc.docs[0].data();
						const response = await fetch(fileData.file);
						const encryptedContent = await response.arrayBuffer();

						// Retrieve key and IV from Firestore
						key = await crypto.subtle.importKey(
							"raw",
							new Uint8Array(fileData.key),
							"AES-GCM",
							true,
							["encrypt", "decrypt"]
						);
						iv = new Uint8Array(fileData.iv);

						// Decrypt the existing content
						existingContent = await decryptFile(encryptedContent, key, iv);
					}
				}
			}

			console.log("existing content", existingContent);
			const newContent = append ? existingContent + "\n" + text : text;
			console.log("new content", newContent);

			// If not appending, generate new key and IV
			if (!key || !iv) {
				key = await generateAESKey(256);
				iv = crypto.getRandomValues(new Uint8Array(16));
			}

			const fileBuffer = new TextEncoder().encode(newContent);
			const encryptedContent = await encryptFile(fileBuffer, key, iv);
			const blob = new Blob([encryptedContent], {
				type: "application/octet-stream",
			});

			// Upload the encrypted file to Firebase Storage
			await uploadBytes(storageRef, blob);
			const downloadURL = await getDownloadURL(storageRef);

			if (append) {
				const fileDoc = await getDocs(
					query(
						collection(db, `users/${user.uid}/knowledgebase`),
						where("name", "==", fileName)
					)
				);
				if (!fileDoc.empty) {
					await updateDoc(
						doc(db, `users/${user.uid}/knowledgebase`, fileDoc.docs[0].id),
						{
							file: downloadURL,
							updatedAt: serverTimestamp(),
						}
					);
				}
			} else {
				const keyBuffer = await crypto.subtle.exportKey("raw", key);
				await addDoc(collection(db, `users/${user.uid}/knowledgebase`), {
					name: fileName,
					file: downloadURL,
					createdAt: serverTimestamp(),
					form: "Voice Dump",
					iv: Array.from(iv),
					key: Array.from(new Uint8Array(keyBuffer)),
					uploadedBy: user.uid,
				});
			}

			console.log("Encrypted text file saved successfully");
			alert("File saved successfully!");
		} catch (error) {
			console.error("Error saving text file:", error);
			alert("Error saving file. Please try again.");
		}
	};

	const handleSave = () => {
		if (transcript) {
			setShowFileNameModal(true);
		}
	};

	const handleModalSave = async () => {
		if (!fileName) {
			alert("Please enter a file name.");
			return;
		}

		const finalFileName = fileName.endsWith(".txt")
			? fileName
			: `${fileName}.txt`;

		if (
			!isAppending &&
			existingFiles.some((file) => file.name === finalFileName)
		) {
			alert(
				"A file with this name already exists. Please choose a different name or select 'Append to existing file'."
			);
			return;
		}

		await saveTextFile(transcript, finalFileName, isAppending);
		setShowFileNameModal(false);
		setFileName("");
		setIsAppending(false);
	};

	return (
		<div
			className={`main-content voice-upload-container main-color-${switchChecked}`}
		>
			<button onClick={listening ? stopListening : startListening}>
				{listening ? (
					<p className="start-voice">
						<FaRegCircleStop /> Stop Speaking
					</p>
				) : (
					<p className="start-voice">
						<FaRegCirclePlay /> Start Speaking
					</p>
				)}
			</button>
			{transcript && !listening && (
				<button onClick={handleSave} disabled={!transcript}>
					<p className="start-voice">
						<FaRegSave />
						Save Transcript
					</p>
				</button>
			)}
			<p className="voice-transcript">{transcript}</p>

			{showFileNameModal && (
				<div className="modal">
					<div className="modal-content">
						<h3>Save Transcript</h3>
						<input
							type="text"
							value={fileName}
							onChange={(e) => setFileName(e.target.value)}
							placeholder="Enter file name"
						/>
						<div className="checkbox-container">
							<input
								type="checkbox"
								id="appendCheckbox"
								checked={isAppending}
								onChange={() => setIsAppending(!isAppending)}
							/>
							<label htmlFor="appendCheckbox">Add to existing Voice Drop</label>
						</div>
						{isAppending && (
							<select
								value={fileName}
								onChange={(e) => setFileName(e.target.value)}
							>
								<option value="">Select a file to add to</option>
								{existingFiles.map((file) => (
									<option key={file.id} value={file.name}>
										{file.name}
									</option>
								))}
							</select>
						)}
						<div className="modal-buttons">
							<button onClick={() => setShowFileNameModal(false)}>
								Cancel
							</button>
							<button onClick={handleModalSave}>Save</button>
						</div>
					</div>
				</div>
			)}

			{listening && (
				<img ref={logoRef} src="/logo512.png" alt="Logo" className="logo" />
			)}
		</div>
	);
};

export default VoiceUpload;
