import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/Sidebar.css";
import { auth } from "../firebaseConfig";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Switch from "react-switch";
import {
	// MdDashboard,
	MdHome,
	MdExitToApp,
	MdMenu,
	// MdWaves,
	MdOutlineHelpOutline,
	MdOutlineLightbulb,
} from "react-icons/md";
import {
	HiUserGroup,
	// eslint-disable-next-line
	HiIdentification,
	HiOutlineShoppingBag,
} from "react-icons/hi";
import { IoDocumentSharp } from "react-icons/io5";
import { GiBrain, GiGreekTemple, GiPerson } from "react-icons/gi";
import { GrCloudlinux } from "react-icons/gr";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";

const Sidebar = ({
	startingTokens,
	tokenCount,
	user,
	switchChecked,
	setSwitchChecked,
}) => {
	const [userEmailClicked, setUserEmailClicked] = useState(false);
	const [isCreateNewOpen, setIsCreateNewOpen] = useState(false);
	const [isCreateNewKnowledgeOpen, setIsCreateNewKnowledgeOpen] =
		useState(false);
	const [isCreateNewAgentOpen, setIsCreateNewAgentOpen] = useState(false);
	const [isCreateNewFlowOpen, setIsCreateNewFlowOpen] = useState(false);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const sidebarRef = useRef(null);

	useEffect(() => {
		document.body.className = switchChecked ? "dark-theme" : "light-theme";
	}, [switchChecked]);

	const handleLogout = () => {
		auth.signOut();
	};

	const percentage =
		startingTokens > 0 ? (tokenCount / startingTokens) * 100 : 0;

	const handleClickOutside = (event) => {
		if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
			setSidebarOpen(false);
		}
	};

	useEffect(() => {
		if (sidebarOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [sidebarOpen]);

	const handleLinkClick = () => {
		setSidebarOpen(false);
	};

	const handleSwitchChange = (checked) => {
		setSwitchChecked(checked);
		localStorage.setItem("switchChecked", checked);
	};

	return (
		<div>
			<MdMenu
				className={`mobile-menu mobile-menu-${switchChecked}`}
				onClick={() => setSidebarOpen(!sidebarOpen)}
			/>
			<div
				className={`sidebar ${
					sidebarOpen ? "open" : ""
				} main-color-${switchChecked} sideBar-color-${switchChecked}`}
				ref={sidebarRef}
			>
				<ul className="sidebar-list">
					<li className="mainNav">
						<MdHome className={`create-plus-${switchChecked}`} />
						<Link to="/" onClick={handleLinkClick}>
							Dashboard
						</Link>
					</li>
					<li
						className="mainNav departments-nav-section"
						onMouseEnter={() => {
							setIsCreateNewOpen(true);
							setIsCreateNewAgentOpen(false);
							setIsCreateNewKnowledgeOpen(false);
							setIsCreateNewFlowOpen(false);
						}}
					>
						<GiGreekTemple className={`create-plus-${switchChecked}`} />
						<Link to="/departments-main" onClick={handleLinkClick}>
							Departments
						</Link>
					</li>
					{isCreateNewOpen && (
						<div
							className={`create-new ${isCreateNewOpen ? "open" : ""} `}
							onMouseLeave={() => setIsCreateNewOpen(false)}
						>
							<ul>
								{/* <li className="mainNav">
									<Link to="/departments-main" onClick={handleLinkClick}>
										New Department
									</Link>
								</li> */}
								{/* <li className="mainNav">
									<Link to="/pro-departments" onClick={handleLinkClick}>
										Pro Department
									</Link>
								</li> */}
								{/* <li className="mainNav">
									<Link to="/personal-departments" onClick={handleLinkClick}>
										My Departments
									</Link>
								</li> */}
								<li className="mainNav">
									<Link to="/shared-departments" onClick={handleLinkClick}>
										Shared Departments
									</Link>
								</li>
							</ul>
						</div>
					)}
					{/* <li className="mainNav">
						<SiSpinrilla className={`create-plus-${switchChecked}`} />
						<Link to="/all-threads" onClick={handleLinkClick}>
							My Agents
						</Link>
					</li> */}
					<li
						className="mainNav agents-nav-section"
						onMouseEnter={() => {
							setIsCreateNewAgentOpen(true);
							setIsCreateNewFlowOpen(false);
							setIsCreateNewKnowledgeOpen(false);
							setIsCreateNewOpen(false);
						}}
					>
						<GiPerson className={`create-plus-${switchChecked}`} />
						<Link to="/create-custom-bot" onClick={handleLinkClick}>
							Agents
						</Link>
					</li>
					{isCreateNewAgentOpen && (
						<div
							className={`create-new ${isCreateNewAgentOpen ? "open" : ""} `}
							onMouseLeave={() => setIsCreateNewAgentOpen(false)}
						>
							<ul>
								{/* <li className="mainNav">
									<Link to="/create-custom-bot" onClick={handleLinkClick}>
										New Agent
									</Link>
								</li> */}
								<li className="mainNav">
									<Link to="/pro-agents" onClick={handleLinkClick}>
										Pro Agents
									</Link>
								</li>
								<li className="mainNav">
									<Link to="/my-agents" onClick={handleLinkClick}>
										My Agents
									</Link>
								</li>
								<li className="mainNav">
									<Link to="/shared-agents" onClick={handleLinkClick}>
										Shared Agents
									</Link>
								</li>
							</ul>
						</div>
					)}
					<li
						className="mainNav flows-nav-section"
						onMouseEnter={() => {
							setIsCreateNewFlowOpen(true);
							setIsCreateNewAgentOpen(false);
							setIsCreateNewKnowledgeOpen(false);
							setIsCreateNewOpen(false);
						}}
					>
						<GrCloudlinux className={`create-plus-${switchChecked}`} />
						<Link to="/users/:userId/workflows" onClick={handleLinkClick}>
							Flows
						</Link>
					</li>
					{isCreateNewFlowOpen && (
						<div
							className={`create-new ${isCreateNewFlowOpen ? "open" : ""} `}
							onMouseLeave={() => setIsCreateNewFlowOpen(false)}
						>
							<ul>
								{/* <li className="mainNav">
									<Link to="/users/:userId/workflows" onClick={handleLinkClick}>
										New Flow
									</Link>
								</li> */}
								<li className="mainNav">
									<Link to="/pro-flows" onClick={handleLinkClick}>
										Pro Flows
									</Link>
								</li>
								<li className="mainNav">
									<Link to="/my-flows" onClick={handleLinkClick}>
										My Flows
									</Link>
								</li>
								<li className="mainNav">
									<Link to="/shared-flows" onClick={handleLinkClick}>
										Shared Flows
									</Link>
								</li>
							</ul>
						</div>
					)}
					<li
						className="mainNav knowledgebase-nav-section"
						onMouseEnter={() => {
							setIsCreateNewKnowledgeOpen(true);
							setIsCreateNewAgentOpen(false);
							setIsCreateNewFlowOpen(false);
							setIsCreateNewOpen(false);
						}}
					>
						<GiBrain className={`create-plus-${switchChecked}`} />
						<Link to="/knowledge-base" onClick={handleLinkClick}>
							Knowledge Center
						</Link>
					</li>
					{isCreateNewKnowledgeOpen && (
						<div
							className={`create-new ${
								isCreateNewKnowledgeOpen ? "open" : ""
							} `}
							onMouseLeave={() => setIsCreateNewKnowledgeOpen(false)}
						>
							<ul>
								{/* <li className="mainNav">
									<Link to="/add-knowledge-base" onClick={handleLinkClick}>
										New Knowledge
									</Link>
								</li> */}
								<li className="mainNav">
									<Link to="/pro-knowledge-base" onClick={handleLinkClick}>
										Pro Knowledge
									</Link>
								</li>
								<li className="mainNav">
									<Link to="/all-knowledge-base" onClick={handleLinkClick}>
										My Knowledge
									</Link>
								</li>
								<li className="mainNav">
									<Link to="/shared-knowledge-base" onClick={handleLinkClick}>
										Shared Knowledge
									</Link>
								</li>
								{/* <li className="mainNav">
									<Link to="/zapier-react" onClick={handleLinkClick}>
										Zapier Integration
									</Link>
								</li> */}
							</ul>
						</div>
					)}
				</ul>
				<div className="user">
					<div
						onMouseEnter={() => setUserEmailClicked(true)}
						onClick={() => setUserEmailClicked(!userEmailClicked)}
						className="avatar"
					>
						{user.photoURL ? (
							<img src={user.photoURL} alt="user" />
						) : (
							user.email[0].toUpperCase()
						)}
					</div>
					{userEmailClicked && (
						<div
							className={`userSettings userSettings-color-${switchChecked}`}
							onMouseLeave={() => setUserEmailClicked(!userEmailClicked)}
						>
							<div style={{ height: 50 }} className="token-percent">
								<CircularProgressbar
									value={percentage}
									text={`${Math.round(percentage)}%`}
									styles={buildStyles({
										textColor: switchChecked ? "black" : "white",
										pathColor: "green",
										trailColor: "red",
									})}
								/>
								<button className="logoutBtn">
									{tokenCount} Tokens Remain
								</button>
							</div>
							<Link
								to="/customize-SpinFlow"
								onClick={handleLinkClick}
								className="logoutBtn"
							>
								<HiMiniWrenchScrewdriver
									className={`create-plus-${switchChecked}`}
								/>
								Customize SpinFlow
							</Link>
							<Link
								to="/team-members"
								onClick={handleLinkClick}
								className="logoutBtn"
							>
								<HiUserGroup className={`create-plus-${switchChecked}`} />
								Team Members
							</Link>
							<Link
								to="/credentials"
								onClick={handleLinkClick}
								className="logoutBtn"
							>
								<HiIdentification className={`create-plus-${switchChecked}`} />
								Add Credentials
							</Link>
							<Link
								to="/manage-subscription"
								onClick={handleLinkClick}
								className="logoutBtn"
							>
								<HiOutlineShoppingBag
									className={`create-plus-${switchChecked}`}
								/>
								Manage Subscription
							</Link>
							<div className="switch-container logoutBtn">
								<MdOutlineLightbulb
									className={`create-plus-${switchChecked}`}
								/>
								<p>Light Mode</p>
								<Switch
									onChange={handleSwitchChange}
									checked={switchChecked}
									checkedIcon={false}
									uncheckedIcon={false}
								/>
							</div>
							<button onClick={handleLogout} className="logoutBtn">
								<MdExitToApp className={`create-plus-${switchChecked}`} />
								Logout
							</button>
						</div>
					)}
				</div>
				<div className="info-sidebar">
					<div className="documentations">
						<IoDocumentSharp className={`create-plus-${switchChecked}`} />
						<Link to="/documentation">Documentation</Link>
					</div>
					<div>
						<MdOutlineHelpOutline className={`create-plus-${switchChecked}`} />
						<Link to="/information">Need Help?</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
