import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
	collection,
	getDocs,
	doc,
	getDoc,
	query,
	where,
	deleteDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import "../Bots/Styles/AllThreads.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const MyFlow = ({ user, switchChecked, selectedDepartment }) => {
	const [workflows, setWorkflows] = useState([]);
	const [teamId, setTeamId] = useState(null);
	const [departmentsMap, setDepartmentsMap] = useState({});

	useEffect(() => {
		const loadUserData = async () => {
			try {
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);

				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					const teamId = userData.teamId;
					setTeamId(teamId);

					if (teamId) {
						// Fetch departments first to create a mapping
						const departmentsSnapshot = await getDocs(
							collection(db, `teams/${teamId}/departments`)
						);
						let departmentsData = {};
						departmentsSnapshot.forEach((doc) => {
							departmentsData[doc.id] = {
								name: doc.data().name,
								color: doc.data().color,
							};
						});
						setDepartmentsMap(departmentsData);

						// Fetch flows created by the user
						const userFlowsQuery = query(
							collection(db, `teams/${teamId}/flows`),
							where("createdBy", "==", user.uid)
						);

						const querySnapshot = await getDocs(userFlowsQuery);
						let loadedWorkflows = [];
						querySnapshot.forEach((doc) => {
							loadedWorkflows.push({ id: doc.id, ...doc.data() });
						});

						// Filter workflows based on selectedDepartment
						if (selectedDepartment && selectedDepartment.id !== "all") {
							loadedWorkflows = loadedWorkflows.filter(
								(workflow) =>
									workflow.departments &&
									workflow.departments.includes(selectedDepartment.id)
							);
						}

						setWorkflows(loadedWorkflows);
					} else {
						console.error("User is not part of a team");
					}
				} else {
					console.error("User document not found");
				}
			} catch (error) {
				console.error("Error loading workflows: ", error);
			}
		};

		loadUserData();
	}, [user.uid, selectedDepartment]);

	const renderDepartments = (workflow) => {
		if (workflow.departments && workflow.departments.length > 0) {
			const departmentNames = workflow.departments.map(
				(deptId) => departmentsMap[deptId]?.name || deptId
			);
			return departmentNames.join(", ");
		}
		return "No Department";
	};

	const handleDeleteFlow = async (flowId) => {
		if (window.confirm("Are you sure you want to delete this flow?")) {
			try {
				await deleteDoc(doc(db, `teams/${teamId}/flows`, flowId));
				setWorkflows(workflows.filter((workflow) => workflow.id !== flowId));
				alert("Flow deleted successfully!");
			} catch (error) {
				console.error("Error deleting flow:", error);
				alert("Failed to delete flow. Please try again.");
			}
		}
	};

	const renderWorkflowCard = (workflow) => {
		const departmentColor =
			workflow.departments && workflow.departments.length > 0
				? departmentsMap[workflow.departments[0]]?.color
				: "transparent";

		return (
			<li
				key={workflow.id}
				className={`card card-color-${switchChecked} card-border`}
				style={{ borderTop: `10px solid ${departmentColor}` }}
			>
				<div className="ttl">{workflow.templateName?.toUpperCase()}</div>
				<p>Department: {renderDepartments(workflow)}</p>
				<div className="card-actions">
					<Link
						onClick={() => handleDeleteFlow(workflow.id)}
						className="main-content-button"
					>
						<FontAwesomeIcon icon={faTrashAlt} />
					</Link>
					<Link
						to={`/users/${user.uid}/workflows/${workflow.id}`}
						className="main-content-button"
					>
						Continue
					</Link>
				</div>
			</li>
		);
	};

	return (
		<div
			className={`main-content template-content main-color-${switchChecked}`}
		>
			<div className="template-header">
				<h2>My Flows</h2>
			</div>
			{workflows.length === 0 ? (
				<div
					className={`empty-threads card card-color-${switchChecked} card-border`}
					style={{ borderTop: "2px solid transparent" }}
				>
					<h3>Oops! It doesn't look like you have any existing flows.</h3>
					<h4>Let's create one!</h4>
					<Link
						to={`/users/${user.uid}/workflows`}
						className="main-content-button"
					>
						Create new flow
					</Link>
				</div>
			) : (
				<ul className="card-list my-threads">
					{workflows.map((workflow) => renderWorkflowCard(workflow))}
				</ul>
			)}
		</div>
	);
};

export default MyFlow;
