import React, { useState, useEffect } from "react";
import "../Styles/EditSubscription.css";
import { db } from "../firebaseConfig";
import {
	doc,
	getDoc,
	updateDoc,
	collection,
	getDocs,
} from "firebase/firestore";

const TeamMembers = ({ user, switchChecked }) => {
	const [accountType, setAccountType] = useState("");
	const [levelType, setLevelType] = useState("");
	const [loading, setLoading] = useState(false);
	const [teamMembers, setTeamMembers] = useState([]);
	const [newMemberEmail, setNewMemberEmail] = useState("");

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userDoc = await getDoc(doc(db, "users", user.uid));
				if (userDoc.exists()) {
					const userData = userDoc.data();
					setAccountType(userData.accountType);
					setLevelType(userData.levelType);
				}
			} catch (error) {
				console.error("Error fetching user data: ", error);
			}
		};

		const fetchTeamMembers = async () => {
			try {
				const userDoc = await getDoc(doc(db, "users", user.uid));
				if (userDoc.exists()) {
					const userData = userDoc.data();
					if (userData.accountType) {
						const teamId = userData.teamId;
						const membersSnapshot = await getDocs(collection(db, "users"));
						const membersData = membersSnapshot.docs
							.map((doc) => ({
								id: doc.id,
								...doc.data(),
							}))
							.filter((member) => member.teamId === teamId);
						setTeamMembers(membersData);
					}
				}
			} catch (error) {
				console.error("Error fetching team members: ", error);
			}
		};

		fetchUserData();
		fetchTeamMembers();
	}, [user.uid]);
	// eslint-disable-next-line
	const handleLevelChange = (id, newLevel) => {
		setTeamMembers((prevMembers) =>
			prevMembers.map((member) =>
				member.id === id ? { ...member, levelType: newLevel } : member
			)
		);
	};
	// eslint-disable-next-line
	const handleSaveChanges = async () => {
		setLoading(true);
		try {
			const updatePromises = teamMembers.map((member) =>
				updateDoc(doc(db, "users", member.id), {
					levelType: member.levelType,
				})
			);
			await Promise.all(updatePromises);
			alert("Changes saved successfully!");
		} catch (error) {
			console.error("Error saving changes:", error);
			alert(`An error occurred: ${error.message}. Please try again.`);
		} finally {
			setLoading(false);
		}
	};

	const handleAddMember = async () => {
		if (!newMemberEmail.trim()) {
			alert("Please enter a valid email address.");
			return;
		}

		if (teamMembers.length >= 5) {
			alert("Your team already has 5 members. You cannot add more.");
			return;
		}

		setLoading(true);
		try {
			const userDoc = await getDoc(doc(db, "users", user.uid));
			if (userDoc.exists()) {
				const userData = userDoc.data();
				const teamId = userData.teamId;

				// Send invite
				await fetch(
					"https://us-central1-spinflow-13626.cloudfunctions.net/sendTeamInvites",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							emails: [newMemberEmail],
							inviter: user.email,
							teamId,
						}),
					}
				);

				alert("Invitation sent successfully!");

				setNewMemberEmail("");
			}
		} catch (error) {
			console.error("Error sending invitation:", error);
			alert(`An error occurred: ${error.message}. Please try again.`);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			className={`main-content edit-subscription main-color-${switchChecked}`}
		>
			<div className="white-box-overlay team-members-container">
				<h2>My Team</h2>
				{accountType && (
					<div className="team-members">
						<div className="individual-team-members">
							{teamMembers.map((member) => (
								<div key={member.id} className="team-member">
									<span style={{ padding: "10px" }}>{member.email}</span>
									{/* {levelType === "admin" ? (
										<select
											value={member.levelType}
											onChange={(e) =>
												handleLevelChange(member.id, e.target.value)
											}
										>
											<option value="staff">Staff</option>
											<option value="manager">Manager</option>
											<option value="admin">Admin</option>
										</select>
									) : (
										<span>{member.levelType}</span>
									)} */}
								</div>
							))}
						</div>
						{levelType === "admin" && (
							<>
								{/* <button
									onClick={handleSaveChanges}
									disabled={loading}
									className="main-content-button"
								>
									Save Changes
								</button> */}
								<div className="add-member">
									<h4>Add New Team Member</h4>
									<input
										type="email"
										value={newMemberEmail}
										onChange={(e) => setNewMemberEmail(e.target.value)}
										placeholder="Enter new member's email"
									/>
									<button
										onClick={handleAddMember}
										disabled={loading}
										className="main-content-button"
									>
										Add Member
									</button>
								</div>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default TeamMembers;
