import React, { useEffect, useState } from "react";
import {
	collection,
	getDocs,
	doc,
	getDoc,
	query,
	where,
	setDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import "../Styles/MainContent.css";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import CustomizeModal from "./CustomizeModal";
import OpenAI from "openai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPaperPlane,
	faSpinner,
	faUpRightAndDownLeftFromCenter,
	faDownLeftAndUpRightToCenter,
} from "@fortawesome/free-solid-svg-icons";
import { marked } from "marked";

const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

const MainContent = ({ user, switchChecked, selectedDepartment }) => {
	const [recentItems, setRecentItems] = useState([]);
	const [mostUsedItems, setMostUsedItems] = useState([]);
	const [past30DaysItems, setPast30DaysItems] = useState([]);
	const [teamMembers, setTeamMembers] = useState([]);
	const [departments, setDepartments] = useState({});
	const [error, setError] = useState(null);
	const [userRole, setUserRole] = useState(null);
	const [teamId, setTeamId] = useState(null);
	const [recentMessages, setRecentMessages] = useState([]);
	const navigate = useNavigate();
	const [isCustomizeModalOpen, setIsCustomizeModalOpen] = useState(false);
	const [customization, setCustomization] = useState({
		showMostUsed: true,
		showRecentlyCreated: true,
		showPast30Days: true,
		showMyTeam: true,
		showMyMessages: true,
	});
	// eslint-disable-next-line
	const [assistantResponse, setAssistantResponse] = useState("");
	const [userMessage, setUserMessage] = useState("");
	const [chatHistory, setChatHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [assistant, setAssistant] = useState(null);
	const [isChatFocused, setIsChatFocused] = useState(false);
	const [isFullyHidden, setIsFullyHidden] = useState(false); // New state to handle display

	useEffect(() => {
		const fetchUserData = async () => {
			if (user && user.uid) {
				try {
					const userDocRef = doc(db, "users", user.uid);
					const userDocSnap = await getDoc(userDocRef);
					if (userDocSnap.exists()) {
						const userData = userDocSnap.data();
						setUserRole(userData.levelType);
						setTeamId(userData.teamId);
						setAssistant(userData.assistantId);
					} else {
						console.error("User document does not exist");
					}
				} catch (error) {
					setError("Error fetching user data.");
					console.error("Error fetching user data: ", error);
				}
			}
		};

		fetchUserData();
	}, [user]);

	useEffect(() => {
		const fetchDepartments = async () => {
			if (teamId) {
				const departmentsSnapshot = await getDocs(
					collection(db, `teams/${teamId}/departments`)
				);
				const departmentsData = {};
				departmentsSnapshot.forEach((doc) => {
					departmentsData[doc.id] = doc.data();
				});
				setDepartments(departmentsData);
			}
		};

		fetchDepartments();
	}, [teamId]);

	useEffect(() => {
		const fetchAssistantsAndFlows = async () => {
			if (user && user.uid && teamId) {
				try {
					let combinedItems = [];

					// Fetch team assistants
					const teamAssistantsQuery = query(
						collection(db, `teams/${teamId}/assistants`),
						where("isPersonal", "==", false)
					);
					const personalAssistantsQuery = query(
						collection(db, `teams/${teamId}/assistants`),
						where("isPersonal", "==", true),
						where("createdBy", "==", user.uid)
					);
					const [teamAssistantsSnapshot, personalAssistantsSnapshot] =
						await Promise.all([
							getDocs(teamAssistantsQuery),
							getDocs(personalAssistantsQuery),
						]);

					const teamAssistants = teamAssistantsSnapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
						type: "Team",
						itemType: "Assistant",
					}));
					const personalAssistants = personalAssistantsSnapshot.docs.map(
						(doc) => ({
							...doc.data(),
							id: doc.id,
							type: "Personal",
							itemType: "Assistant",
						})
					);
					combinedItems = [
						...combinedItems,
						...teamAssistants,
						...personalAssistants,
					];

					// Fetch team flows
					const teamFlowsQuery = query(
						collection(db, `teams/${teamId}/flows`),
						where("isPersonal", "==", false)
					);
					const personalFlowsQuery = query(
						collection(db, `teams/${teamId}/flows`),
						where("isPersonal", "==", true),
						where("createdBy", "==", user.uid)
					);
					const [teamFlowsSnapshot, personalFlowsSnapshot] = await Promise.all([
						getDocs(teamFlowsQuery),
						getDocs(personalFlowsQuery),
					]);

					const teamFlows = teamFlowsSnapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
						type: "Team",
						itemType: "Flow",
					}));
					const personalFlows = personalFlowsSnapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
						type: "Personal",
						itemType: "Flow",
					}));
					combinedItems = [...combinedItems, ...teamFlows, ...personalFlows];

					// Filter items based on selected department
					const filteredItems =
						selectedDepartment && selectedDepartment.id !== "all"
							? combinedItems.filter(
									(item) =>
										item.departments &&
										item.departments.includes(selectedDepartment.id)
							  )
							: combinedItems;

					// Most used based on conversation length (only applicable for assistants)
					const sortedByUsage = filteredItems
						.filter((item) => item.itemType === "Assistant")
						.sort(
							(a, b) =>
								(b.conversation?.length || 0) - (a.conversation?.length || 0)
						)
						.slice(0, 5);
					setMostUsedItems(sortedByUsage);

					// Recently created
					const sortedByCreatedAt = filteredItems
						.filter((item) => item.createdAt)
						.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
					setRecentItems(sortedByCreatedAt);

					// Past 30 days
					const thirtyDaysAgo = new Date();
					thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
					const past30DaysData = sortedByCreatedAt.filter(
						(item) => item.createdAt && item.createdAt.toDate() >= thirtyDaysAgo
					);
					setPast30DaysItems(past30DaysData);

					// Fetch team members
					const membersSnapshot = await getDocs(collection(db, "users"));
					const membersData = membersSnapshot.docs
						.map((doc) => ({
							id: doc.id,
							...doc.data(),
						}))
						.filter((member) => member.teamId === teamId);
					setTeamMembers(membersData);
				} catch (error) {
					setError("Error fetching assistants and flows.");
					console.error("Error fetching data: ", error);
				}
			}
		};

		if (teamId && userRole) {
			fetchAssistantsAndFlows();
		}
	}, [user, teamId, userRole, selectedDepartment]);

	useEffect(() => {
		const fetchRecentChats = async () => {
			if (user && user.uid && teamId) {
				try {
					const chatsRef = collection(db, "teams", teamId, "chats");
					const chatsSnapshot = await getDocs(chatsRef);

					let recentChats = chatsSnapshot.docs
						.map((doc) => {
							const chatData = doc.data();
							if (chatData.participants.includes(user.email)) {
								const otherParticipant = chatData.participants.find(
									(p) => p !== user.email
								);
								return {
									id: doc.id,
									otherParticipant: otherParticipant,
									lastUpdated: chatData.lastUpdated,
								};
							}
							return null;
						})
						.filter(Boolean);

					recentChats.sort((a, b) => b.lastUpdated - a.lastUpdated);
					setRecentMessages(recentChats.slice(0, 5));
				} catch (error) {
					setError("Error fetching recent chats.");
					console.error("Error fetching recent chats: ", error);
				}
			}
		};

		fetchRecentChats();
	}, [user, teamId]);

	useEffect(() => {
		const fetchCustomization = async () => {
			if (user && user.uid) {
				try {
					const customizationDoc = await getDoc(
						doc(db, "users", user.uid, "preferences", "dashboard")
					);
					if (customizationDoc.exists()) {
						setCustomization(customizationDoc.data());
					}
				} catch (error) {
					console.error("Error fetching customization:", error);
				}
			}
		};

		fetchCustomization();
	}, [user]);

	const handleCustomize = () => {
		setIsCustomizeModalOpen(true);
	};

	const handleCustomizationSave = async (newCustomization) => {
		setCustomization(newCustomization);
		setIsCustomizeModalOpen(false);

		if (user && user.uid) {
			try {
				await setDoc(
					doc(db, "users", user.uid, "preferences", "dashboard"),
					newCustomization
				);
			} catch (error) {
				console.error("Error saving customization:", error);
			}
		}
	};

	const renderDepartments = (item) => {
		if (item.type === "Personal") {
			return "Personal";
		}
		if (item.departments && item.departments.length > 0) {
			return item.departments
				.map((deptId) => departments[deptId]?.name)
				.join(", ");
		}
		return "No Department";
	};

	const generateLinkTo = (item) => {
		return item.itemType === "Assistant"
			? `/chat/${item.id}`
			: `/users/${user.uid}/workflows/${item.id}`;
	};

	const renderCard = (item, index) => {
		const departmentColor =
			item.departments && item.departments.length > 0
				? departments[item.departments[0]]?.color
				: "transparent";

		return (
			<div
				key={index}
				className={`card card-color-${switchChecked} card-border`}
				style={{ borderTop: `10px solid ${departmentColor}` }}
			>
				<h3>
					{item.name?.toUpperCase() ||
						item.templateName?.toUpperCase() ||
						"Unnamed"}
				</h3>
				<p>
					{item.itemType === "Assistant" ? "Agent" : item.itemType} ({item.type}
					)
				</p>
				<p>Department: {renderDepartments(item)}</p>
				<Link
					to={generateLinkTo(item)}
					className="main-content-button home-button"
				>
					Continue
				</Link>
			</div>
		);
	};

	const handleSendMessage = async () => {
		if (!userMessage.trim()) return;

		setIsLoading(true);
		const newMessage = { role: "user", content: userMessage };
		setChatHistory((prev) => [...prev, newMessage]);

		try {
			const thread = await openai.beta.threads.create();
			await openai.beta.threads.messages.create(thread.id, newMessage);

			const run = await openai.beta.threads.runs.create(thread.id, {
				assistant_id: assistant,
			});

			let runStatus = await openai.beta.threads.runs.retrieve(
				thread.id,
				run.id
			);

			while (runStatus.status !== "completed") {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
			}

			const messages = await openai.beta.threads.messages.list(thread.id);
			const latestMessage = messages.data[0];

			setAssistantResponse(latestMessage.content[0].text.value);
			setChatHistory((prev) => [
				...prev,
				{ role: "assistant", content: latestMessage.content[0].text.value },
			]);
		} catch (error) {
			console.error("Error communicating with assistant:", error);
			setAssistantResponse("Sorry, I encountered an error. Please try again.");
		} finally {
			setIsLoading(false);
			setUserMessage("");
		}
	};

	const handleChatFocus = () => {
		setIsChatFocused(true);
	};

	// const handleChatBlur = () => {
	// 	setIsChatFocused(false);
	// };
	const formatApiResponse = (apiResponse) => {
		let formattedResponse = apiResponse.replace(/【\d+:\d+†source】/g, "");
		return marked(formattedResponse);
	};

	// Hide elements with smooth transition and display: none after opacity
	useEffect(() => {
		if (isChatFocused) {
			setTimeout(() => {
				setIsFullyHidden(true);
			}, 300); // Matches the CSS transition duration
		} else {
			setIsFullyHidden(false); // Reset display on focus out
		}
	}, [isChatFocused]);

	return (
		<div
			className={`main-content main-color-${switchChecked} ${
				isChatFocused ? "fullscreen" : ""
			}`}
		>
			<div className="customize-container">
				<div className="customize" onClick={handleCustomize}>
					<MdOutlineDashboardCustomize className="create-plus-false" />{" "}
					Customize
				</div>
			</div>
			{error && <p className="error">{error}</p>}

			{/* Main Sections */}
			<div
				className="all-main-content"
				style={{ width: "100%", height: "100%", overflowY: "scroll" }}
			>
				<div
					className={`main-rows main-rows-${switchChecked} ${
						isChatFocused ? "hidden" : ""
					} ${isFullyHidden ? "fully-hidden" : ""}`}
					style={{ marginTop: "30px" }}
				>
					{customization.showMostUsed && (
						<div className="section">
							<h3>Most Used</h3>
							<div className="cards">
								{mostUsedItems.length > 0 ? (
									mostUsedItems.map((item, index) => renderCard(item, index))
								) : (
									<div
										className={`card card-color-${switchChecked} card-border`}
									>
										<h3>No items found</h3>
									</div>
								)}
							</div>
						</div>
					)}
					{customization.showRecentlyCreated && (
						<div className="section">
							<h3>Recently Created</h3>
							<div className="cards">
								{recentItems.length > 0 ? (
									recentItems.map((item, index) => renderCard(item, index))
								) : (
									<div
										className={`card card-color-${switchChecked} card-border`}
									>
										<h3>No recently created items</h3>
									</div>
								)}
							</div>
						</div>
					)}
					{customization.showPast30Days && (
						<div className="section">
							<h3>Past 30 Days</h3>
							<div className="cards">
								{past30DaysItems.length > 0 ? (
									past30DaysItems.map((item, index) => renderCard(item, index))
								) : (
									<div
										className={`card card-color-${switchChecked} card-border`}
									>
										<h3>No items from the past 30 days</h3>
									</div>
								)}
							</div>
						</div>
					)}
				</div>

				{/* Chat Section */}
				<div
					className={`${
						isChatFocused
							? `chat-input-container chat-color-${switchChecked}`
							: `minor-rows minor-rows-${switchChecked}`
					}`}
				>
					<div
						className={`${isChatFocused ? "conversation-history" : "section"}`}
						style={{
							padding: !isChatFocused && "0 20px",
						}}
					>
						{!isChatFocused && (
							<h3 style={{ paddingTop: "0px" }}>
								Chat With Your Knowledge Center
							</h3>
						)}
						<div className={`${!isChatFocused && "chat-window-minor"}`}>
							<div
								className="conversation-history"
								style={{
									maxHeight: !isChatFocused && "20vh",
									marginBottom: !isChatFocused && "0",
									width: !isChatFocused && "60vw",
								}}
							>
								<div className={`message assistant`}>
									{/* {isChatFocused ? ( */}
									<div
										className="assistant-text"
										dangerouslySetInnerHTML={{
											__html: `<img src="/logo192.png" alt="SpinFlow" class="chat-img"/> <div class="chat-text">Greetings, Ask me anything! I am fully trained on your personal knowledge center. </div>`,
										}}
									/>
								</div>
								{chatHistory.map((message, index) => (
									<div key={index} className={`message ${message.role}`}>
										{/* {isChatFocused ? ( */}
										<div
											className="assistant-text"
											dangerouslySetInnerHTML={{
												__html:
													message.role === "assistant"
														? `<img src="/logo192.png" alt="SpinFlow" class="chat-img"/> <div class="chat-text"> ${formatApiResponse(
																message.content
														  )}</div>`
														: `${message.content}`,
											}}
										/>
										{/* ) : (
									<>
										<strong>{message.role !== "user" && "Assistant: "}</strong>{" "}
										{message.content}
									</>
								)} */}
									</div>
								))}
							</div>
							{isLoading && (
								<div className="message assistant">
									{isChatFocused ? (
										<div className="assistant-text">
											<img
												src="/logo192.png"
												alt="SpinFlow"
												className="chat-img"
											/>
											<div className="chat-text">
												<FontAwesomeIcon icon={faSpinner} spin /> Assistant is
												thinking...
											</div>
										</div>
									) : (
										"Assistant is typing..."
									)}
								</div>
							)}
						</div>
						<div
							className={
								isChatFocused
									? "chat-input-box"
									: "chat-input-container-creation"
							}
							style={{ width: isChatFocused ? "" : "90%" }}
						>
							{isChatFocused ? (
								<button
									className="send-button"
									onClick={() => setIsChatFocused(false)}
									style={{ marginRight: "10px" }}
								>
									<FontAwesomeIcon
										icon={faDownLeftAndUpRightToCenter}
										fade
										className="maximize-minimize"
									/>
								</button>
							) : (
								<button
									className="send-button"
									onClick={() => setIsChatFocused(true)}
									style={{ marginRight: "10px" }}
								>
									<FontAwesomeIcon
										icon={faUpRightAndDownLeftFromCenter}
										fade
										className="maximize-minimize"
									/>
								</button>
							)}
							{isChatFocused ? (
								<textarea
									value={userMessage}
									onChange={(e) => setUserMessage(e.target.value)}
									placeholder="Type your message here..."
									onFocus={handleChatFocus}
									// onBlur={handleChatBlur}
									id="prompt"
									className="chat-textarea"
									onKeyDown={(e) =>
										e.key === "Enter" && !e.shiftKey && handleSendMessage()
									}
									rows="1"
								/>
							) : (
								<input
									type="text"
									value={userMessage}
									onChange={(e) => setUserMessage(e.target.value)}
									placeholder="Type your message..."
									onFocus={handleChatFocus}
									// onBlur={handleChatBlur}
									className="chat-textarea"
									onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
								/>
							)}
							<button
								onClick={handleSendMessage}
								disabled={isLoading}
								className="send-button"
							>
								{isChatFocused ? (
									<>
										<FontAwesomeIcon
											icon={isLoading ? faSpinner : faPaperPlane}
											spin={isLoading}
										/>{" "}
										Chat
									</>
								) : (
									"Send"
								)}
							</button>
						</div>
					</div>
				</div>

				{/* Team and Messages Sections */}
				<div
					className={`minor-rows non-message-rows minor-rows-${switchChecked} ${
						isChatFocused ? "hidden" : ""
					} ${isFullyHidden ? "fully-hidden" : ""}`}
				>
					{customization.showMyTeam && (
						<div className="section" onClick={() => navigate("/team-members")}>
							<h3>My Team</h3>
							<div className="cards">
								{teamMembers.length > 0 ? (
									teamMembers.map((member) => (
										<div
											key={member.id}
											className={`card card-color-${switchChecked} card-border`}
										>
											<span>{member.email}</span>
										</div>
									))
								) : (
									<div
										className={`card card-color-${switchChecked} card-border`}
									>
										<h3>No team members found</h3>
									</div>
								)}
							</div>
						</div>
					)}
					{customization.showMyMessages && (
						<div className="section" onClick={() => navigate("/messages")}>
							<h3>My Messages</h3>
							<div className="cards">
								{recentMessages.length > 0 ? (
									recentMessages.map((chat, index) => (
										<div
											key={index}
											className={`card card-color-${switchChecked} card-border`}
										>
											<span>{chat.otherParticipant}</span>
											<p>{chat.lastUpdated.toDate().toLocaleString()}</p>
										</div>
									))
								) : (
									<div
										className={`card card-color-${switchChecked} card-border`}
									>
										<h5>No recent conversations</h5>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</div>

			<CustomizeModal
				isOpen={isCustomizeModalOpen}
				onClose={() => setIsCustomizeModalOpen(false)}
				currentCustomization={customization}
				onSave={handleCustomizationSave}
			/>
		</div>
	);
};

export default MainContent;
