import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { db, storage } from "../../firebaseConfig";
import {
	doc,
	getDoc,
	updateDoc,
	collection,
	getDocs,
	addDoc,
	query,
	where,
	serverTimestamp,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import OpenAI from "openai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPaperPlane,
	faSpinner,
	faImage,
	faCopy,
	faShareNodes,
	faTimes,
	faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import "./Styles/AssistantChat.css";
import { marked } from "marked";

// Initialize OpenAI client
const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

const AssistantChat = ({ setTokenCounters, user, switchChecked }) => {
	const { id, type } = useParams();
	const [assistant, setAssistant] = useState(null);
	const [prompt, setPrompt] = useState("");
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line
	const [responseText, setResponseText] = useState("");
	const [conversation, setConversation] = useState([]);
	const [tokenCount, setTokenCount] = useState(0);
	const totalTokenCount = useRef(0);
	const textareaRef = useRef(null);
	const conversationEndRef = useRef(null);
	const [threadId, setThreadId] = useState(null);
	const [uploadedFiles] = useState([]);
	const [assistantId, setAssistantId] = useState(null);
	const [knowledgeBaseFiles, setKnowledgeBaseFiles] = useState([]);
	const responseTextBuffer = useRef("");
	const hasSavedRef = useRef(false);
	const saveTimeoutRef = useRef(null);
	// eslint-disable-next-line
	const [chatKnowledge, setChatKnowledge] = useState(false);
	const [showImageModal, setShowImageModal] = useState(false);
	const [selectedImageUrl, setSelectedImageUrl] = useState("");
	const [startingTokens, setStartingTokens] = useState(0);
	const [enableImageCreation, setEnableImageCreation] = useState(false);
	const [customInstructions, setCustomInstructions] = useState("");
	const [customDescription, setCustomDescription] = useState("");

	// State variables for sharing functionality
	const [departments, setDepartments] = useState([]);
	const [selectedDepartments, setSelectedDepartments] = useState([]);
	const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);
	const [teamId, setTeamId] = useState("");

	// State variables for saving to knowledge base
	const [showSaveModal, setShowSaveModal] = useState(false);
	const [saveFileName, setSaveFileName] = useState("");
	const [saveMode, setSaveMode] = useState("new");
	const [existingFiles, setExistingFiles] = useState([]);
	const [selectedExistingFile, setSelectedExistingFile] = useState("");
	const [contentToSave, setContentToSave] = useState("");

	const fetchAssistant = useCallback(
		async (retryCount = 0) => {
			try {
				const userDocRef = doc(db, `users/${user.uid}`);
				const userDocSnap = await getDoc(userDocRef);
				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					setCustomInstructions(userData.customInstructions || "");
					setCustomDescription(userData.customDescription || "");
					setTeamId(userData.teamId);
					let docRef;
					let isTeamAssistant = false;

					if (type) {
						if (!userData.teamId) {
							console.error("User is part of a team, but teamId is not found.");
							return;
						}
						docRef = doc(db, `teams/${userData.teamId}/assistants`, id);
						isTeamAssistant = true;
					} else {
						docRef = doc(db, `users/${user.uid}/assistants`, id);
					}

					let docSnap = await getDoc(docRef);

					if (!docSnap.exists() && !isTeamAssistant) {
						docRef = doc(db, `teams/${userData.teamId}/assistants`, id);
						docSnap = await getDoc(docRef);
					}

					if (docSnap.exists()) {
						const assistantData = docSnap.data();
						setAssistant(assistantData);
						setThreadId(assistantData.threadId);
						setConversation(assistantData.conversation || []);
						setAssistantId(assistantData.assistantId || null);
						setKnowledgeBaseFiles(assistantData.knowledgeBase);
						setEnableImageCreation(assistantData.enableImageCreation || false);

						if (assistantData.departments) {
							setSelectedDepartments(assistantData.departments);
						}

						if (isTeamAssistant || (type && docSnap.exists())) {
							const teamDocRef = doc(db, `teams/${userData.teamId}`);
							const teamDocSnap = await getDoc(teamDocRef);
							if (teamDocSnap.exists()) {
								const teamData = teamDocSnap.data();
								const initialTokenCount = teamData.tokenCount || 0;
								totalTokenCount.current = initialTokenCount;
								setTokenCount(initialTokenCount);
								setStartingTokens(teamData.tokens);
							}
						} else {
							const initialTokenCount = userData.tokenCount || 0;
							totalTokenCount.current = initialTokenCount;
							setTokenCount(initialTokenCount);
							setStartingTokens(userData.totalTokens);
						}
					} else {
						console.log("No such document!");
					}
				}
			} catch (error) {
				if (error.response && error.response.status === 409 && retryCount < 3) {
					console.error(`409 Conflict error, retrying (${retryCount + 1}/3)`);
					setTimeout(() => fetchAssistant(retryCount + 1), 1000);
				} else {
					console.error("Error fetching assistant:", error);
				}
			}
		},
		[id, type, user.uid]
	);

	useEffect(() => {
		fetchAssistant();
	}, [fetchAssistant]);

	useEffect(() => {
		const fetchDepartments = async () => {
			if (teamId) {
				const departmentsSnapshot = await getDocs(
					collection(db, `teams/${teamId}/departments`)
				);
				const departmentsList = departmentsSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setDepartments(departmentsList);
			}
		};
		fetchDepartments();
	}, [teamId]);

	useEffect(() => {
		const fetchExistingFiles = async () => {
			if (user.uid) {
				const filesSnapshot = await getDocs(
					collection(db, `users/${user.uid}/knowledgebase`)
				);
				const filesList = filesSnapshot.docs
					.map((doc) => ({ id: doc.id, ...doc.data() }))
					.filter((file) => file.name.endsWith(".txt"));
				setExistingFiles(filesList);
			}
		};
		fetchExistingFiles();
	}, [user.uid]);

	const handleDepartmentChange = (e) => {
		const departmentId = e.target.value;
		setSelectedDepartments((prev) =>
			prev.includes(departmentId)
				? prev.filter((id) => id !== departmentId)
				: [...prev, departmentId]
		);
	};

	const handleUpdateSharing = async () => {
		try {
			const userDocRef = doc(db, `users/${user.uid}`);
			const userDocSnap = await getDoc(userDocRef);
			if (userDocSnap.exists()) {
				const userData = userDocSnap.data();
				let docRef;
				if (userData.accountType) {
					docRef = doc(db, `teams/${userData.teamId}/assistants`, id);
					const testDocRef = await getDoc(docRef);
					if (!testDocRef.exists())
						docRef = doc(db, `users/${user.uid}/assistants`, id);
				} else {
					docRef = doc(db, `users/${user.uid}/assistants`, id);
				}

				const isPersonal = selectedDepartments.length === 0;
				await updateDoc(docRef, {
					departments: selectedDepartments,
					isPersonal: isPersonal,
				});

				alert("Assistant sharing settings updated.");
				setIsSharingModalOpen(false);
			}
		} catch (error) {
			console.error("Error updating assistant sharing settings:", error);
		}
	};

	const handlePromptChange = (e) => {
		setPrompt(e.target.value);
		adjustTextareaHeight();
	};

	const calculateTokens = (text) => {
		return text.split(/\s+/).length + Math.floor(text.length / 4);
	};

	const handleSendPrompt = async () => {
		if (tokenCount >= startingTokens) {
			alert("Exceeded Token Usage, please upgrade or purchase more tokens.");
			return;
		}
		if (!threadId) {
			alert("Thread is not ready yet.");
			return;
		}
		if (!assistantId) {
			alert("Assistant ID is not set.");
			return;
		}

		setLoading(true);
		setResponseText("");
		responseTextBuffer.current = "";
		hasSavedRef.current = false;

		try {
			// Combine user prompt with custom instructions and description
			const enhancedPrompt = `${prompt}\n\nCustom GeneralPrefrences: ${customInstructions}\n\nCustom Response Prefrences: ${customDescription}`;
			console.log(enhancedPrompt);
			await openai.beta.threads.messages.create(threadId, {
				role: "user",
				content: enhancedPrompt,
				attachments: uploadedFiles.map((file) => ({
					file_id: file.id,
					tools: [{ type: "file_search" }],
				})),
			});

			// Only display the original prompt in the conversation UI
			const userMessage = { role: "User", content: prompt };
			setConversation((prevConversation) => [...prevConversation, userMessage]);
			setPrompt("");

			const promptTokens = calculateTokens(enhancedPrompt);
			totalTokenCount.current += promptTokens;
			setTokenCount(totalTokenCount.current);

			const stream = openai.beta.threads.runs.stream(threadId, {
				assistant_id: assistantId,
			});

			stream.on("textCreated", (text) => {
				console.log("assistant > ", text);
			});

			stream.on("textDelta", (textDelta) => {
				responseTextBuffer.current += textDelta.value;
				setResponseText(responseTextBuffer.current);

				const responseTokens = calculateTokens(textDelta.value);
				totalTokenCount.current += responseTokens;
				setTokenCount(totalTokenCount.current);

				setConversation((prevConversation) => {
					const updatedConversation = [...prevConversation];
					const lastMessage =
						updatedConversation[updatedConversation.length - 1];

					if (lastMessage && lastMessage.role === "Assistant") {
						lastMessage.content = responseTextBuffer.current;
					} else {
						updatedConversation.push({
							role: "Assistant",
							content: responseTextBuffer.current,
						});
					}

					debounceSaveConversation(
						updatedConversation,
						totalTokenCount.current
					);
					return updatedConversation;
				});
			});

			stream.on("textComplete", async () => {
				console.log(
					"Text stream complete. Final response: ",
					responseTextBuffer.current
				);
				if (!hasSavedRef.current) {
					const newAssistantMessage = {
						role: "Assistant",
						content: responseTextBuffer.current,
					};
					setConversation((prevConversation) => {
						const updatedConversation = [...prevConversation];
						const lastMessage =
							updatedConversation[updatedConversation.length - 1];

						if (lastMessage && lastMessage.role === "Assistant") {
							lastMessage.content = responseTextBuffer.current;
						} else {
							updatedConversation.push(newAssistantMessage);
						}

						hasSavedRef.current = true;
						saveConversationToFirestore(
							updatedConversation,
							totalTokenCount.current
						);
						return updatedConversation;
					});
				}
				setLoading(false);
			});

			stream.on("error", (error) => {
				console.error("Error in stream:", error);
				setLoading(false);
			});
		} catch (error) {
			console.error("Error generating response:", error);
			alert("Error generating response. Check the console for details.");
			setLoading(false);
		}
	};

	const debounceSaveConversation = (updatedConversation, currentTokenCount) => {
		if (saveTimeoutRef.current) {
			clearTimeout(saveTimeoutRef.current);
		}
		saveTimeoutRef.current = setTimeout(() => {
			if (!hasSavedRef.current) {
				saveConversationToFirestore(updatedConversation, currentTokenCount);
				hasSavedRef.current = true;
			}
		}, 5000);
	};

	const saveConversationToFirestore = async (
		updatedConversation,
		currentTokenCount
	) => {
		try {
			const userDocRef = doc(db, `users/${user.uid}`);
			const userDocSnap = await getDoc(userDocRef);
			if (userDocSnap.exists()) {
				const userData = userDocSnap.data();
				let docRef;
				if (userData.accountType) {
					docRef = doc(db, `teams/${userData.teamId}/assistants`, id);
					const testDocRef = await getDoc(docRef);
					if (!testDocRef.exists())
						docRef = doc(db, `users/${user.uid}/assistants`, id);
				} else {
					docRef = doc(db, `users/${user.uid}/assistants`, id);
				}

				await updateDoc(docRef, {
					conversation: updatedConversation,
					updated: new Date(),
				});

				setTokenCounters(currentTokenCount);
				if (userData.accountType) {
					const teamDocRef = doc(db, `teams/${userData.teamId}`);
					await updateDoc(teamDocRef, {
						tokenCount: currentTokenCount,
					});
				} else {
					await updateDoc(userDocRef, {
						tokenCount: currentTokenCount,
					});
				}
			}
			setLoading(false);
		} catch (error) {
			console.error("Error saving conversation to Firestore:", error);
		}
	};

	const adjustTextareaHeight = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = "auto";
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
	};

	useEffect(() => {
		if (conversationEndRef.current) {
			conversationEndRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [conversation]);

	const formatApiResponse = (apiResponse) => {
		let formattedResponse = apiResponse.replace(/【\d+:\d+†source】/g, "");
		return marked(formattedResponse);
	};

	const handleGenerateImage = async () => {
		if (enableImageCreation) {
			try {
				const lastResponseIndex = conversation
					.slice()
					.reverse()
					.findIndex((msg) => msg.role === "Assistant");

				if (lastResponseIndex === -1) {
					alert("No assistant response to generate an image from.");
					return;
				}

				setLoading(true);
				const lastResponse =
					conversation[conversation.length - 1 - lastResponseIndex].content;

				const imageResponse = await openai.images.generate({
					model: "dall-e-3",
					prompt: lastResponse,
					n: 1,
				});

				if (imageResponse?.data?.length) {
					const newImageUrls = imageResponse.data.map((img) => img.url);

					const updatedConversation = conversation.map((message, index) =>
						index === conversation.length - 1 - lastResponseIndex
							? { ...message, images: newImageUrls }
							: message
					);

					totalTokenCount.current += 2181;
					setConversation(updatedConversation);
					saveConversationToFirestore(
						updatedConversation,
						totalTokenCount.current
					);
				} else {
					alert("Failed to generate image.");
				}
				setLoading(false);
			} catch (error) {
				console.error("Error generating image:", error);
				alert("Error generating image. Check the console for details.");
				setLoading(false);
			}
		} else {
			alert("Image creation is not enabled for this assistant.");
		}
	};

	const handleImageClick = (url) => {
		setSelectedImageUrl(url);
		setShowImageModal(true);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			handleSendPrompt();
		}
	};

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				alert("Response copied to clipboard!");
			})
			.catch((err) => {
				console.error("Failed to copy text: ", err);
			});
	};

	const handleOpenSaveModal = (content) => {
		setContentToSave(content);
		setShowSaveModal(true);
	};

	const generateEncryptionKey = async () => {
		const key = await crypto.subtle.generateKey(
			{
				name: "AES-GCM",
				length: 256,
			},
			true,
			["encrypt", "decrypt"]
		);
		const exportedKey = await crypto.subtle.exportKey("raw", key);
		return {
			key: new Uint8Array(exportedKey),
			iv: crypto.getRandomValues(new Uint8Array(16)),
		};
	};

	const encryptData = async (data, key, iv) => {
		const encodedData = new TextEncoder().encode(data);
		const algorithm = { name: "AES-GCM", iv: iv };
		const cryptoKey = await crypto.subtle.importKey(
			"raw",
			key,
			algorithm,
			false,
			["encrypt"]
		);
		const encryptedData = await crypto.subtle.encrypt(
			algorithm,
			cryptoKey,
			encodedData
		);
		return new Uint8Array(encryptedData);
	};

	const decryptData = async (encryptedData, key, iv) => {
		const algorithm = { name: "AES-GCM", iv: iv };
		const cryptoKey = await crypto.subtle.importKey(
			"raw",
			key,
			algorithm,
			false,
			["decrypt"]
		);
		const decryptedData = await crypto.subtle.decrypt(
			algorithm,
			cryptoKey,
			encryptedData
		);
		return new TextDecoder().decode(decryptedData);
	};

	const handleSaveToKnowledgeBase = async () => {
		try {
			let fileContent = contentToSave;
			let fileName = saveFileName.trim();
			let encryptionKey, encryptionIV;

			if (saveMode === "new") {
				if (!fileName) {
					alert("Please enter a file name.");
					return;
				}
				if (fileName.length > 255) {
					alert("File name is too long. Please use a shorter name.");
					return;
				}
				if (!fileName.endsWith(".txt")) {
					fileName += ".txt";
				}
				({ key: encryptionKey, iv: encryptionIV } =
					await generateEncryptionKey());
			} else if (saveMode === "append") {
				if (!selectedExistingFile) {
					alert("Please select a file to append to.");
					return;
				}
				const existingFileRef = doc(
					db,
					`users/${user.uid}/knowledgebase`,
					selectedExistingFile
				);
				const existingFileDoc = await getDoc(existingFileRef);
				if (existingFileDoc.exists()) {
					const existingData = existingFileDoc.data();
					const response = await fetch(existingData.file);
					const encryptedContent = await response.arrayBuffer();
					const decryptedContent = await decryptData(
						new Uint8Array(encryptedContent),
						new Uint8Array(existingData.key),
						new Uint8Array(existingData.iv)
					);
					fileContent = `${decryptedContent}\n\n${contentToSave}`;
					fileName = existingData.name;
					encryptionKey = new Uint8Array(existingData.key);
					encryptionIV = new Uint8Array(existingData.iv);
				} else {
					throw new Error("Selected file does not exist");
				}
			}

			const encryptedContent = await encryptData(
				fileContent,
				encryptionKey,
				encryptionIV
			);
			const storageRef = ref(
				storage,
				`users/${user.uid}/knowledgebase/${fileName}`
			);
			const blob = new Blob([encryptedContent], {
				type: "application/octet-stream",
			});
			await uploadBytes(storageRef, blob);
			const downloadURL = await getDownloadURL(storageRef);

			const fileData = {
				name: fileName,
				file: downloadURL,
				key: Array.from(encryptionKey),
				iv: Array.from(encryptionIV),
				createdAt: serverTimestamp(),
				form: "Assistant Chat",
				uploadedBy: user.uid,
				isTeamFile: false,
			};

			if (saveMode === "new") {
				await addDoc(
					collection(db, `users/${user.uid}/knowledgebase`),
					fileData
				);
			} else {
				const fileDoc = await getDocs(
					query(
						collection(db, `users/${user.uid}/knowledgebase`),
						where("name", "==", fileName)
					)
				);
				if (!fileDoc.empty) {
					await updateDoc(
						doc(db, `users/${user.uid}/knowledgebase`, fileDoc.docs[0].id),
						{
							file: downloadURL,
							updatedAt: serverTimestamp(),
							key: fileData.key,
							iv: fileData.iv,
						}
					);
				}
			}

			alert("Content saved to knowledge base successfully!");
			setShowSaveModal(false);
			setSaveFileName("");
			setSaveMode("new");
			setSelectedExistingFile("");
		} catch (error) {
			console.error("Error saving to knowledge base:", error);
			alert("Failed to save to knowledge base. Please try again.");
		}
	};

	return (
		<div className={`main-content assistant-chat main-color-${switchChecked}`}>
			{assistant ? (
				<>
					<div className="template-header">
						<h2>{assistant.name.toUpperCase()}</h2>
					</div>

					{isSharingModalOpen && (
						<div className="modal-overlay">
							<div className="modal-content">
								<h3>Share Assistant with Departments</h3>
								<div className="department-selection">
									{departments.map((department) => (
										<div key={department.id}>
											<input
												type="checkbox"
												id={`dept-${department.id}`}
												value={department.id}
												checked={selectedDepartments.includes(department.id)}
												onChange={handleDepartmentChange}
											/>
											<label htmlFor={`dept-${department.id}`}>
												{department.name}
											</label>
										</div>
									))}
								</div>
								<div className="modal-buttons">
									<button
										onClick={handleUpdateSharing}
										className="save-sharing-button"
									>
										Save
									</button>
									<button
										onClick={() => setIsSharingModalOpen(false)}
										className="cancel-button"
									>
										Cancel
									</button>
								</div>
							</div>
						</div>
					)}

					<div className={`chat-input-container chat-color-${switchChecked}`}>
						<div className="conversation-history">
							{conversation.map((message, index) => (
								<div key={index} className={`message ${message.role}`}>
									<div
										className="assistant-text"
										dangerouslySetInnerHTML={{
											__html:
												message.role === "Assistant"
													? `<img src="/logo192.png" alt="SpinFlow" class="chat-img"/> <div class="chat-text"> ${formatApiResponse(
															message.content
													  )}</div>`
													: `${message.content}`,
										}}
									></div>
									{message.role === "Assistant" && (
										<div style={{ display: "flex", gap: "10px" }}>
											<div className="customize-containers">
												<button
													className="copy-button main-content-button customize assistant-chat-copy-button"
													onClick={() => copyToClipboard(message.content)}
													title="Copy This Response"
												>
													<FontAwesomeIcon icon={faCopy} />
												</button>
											</div>
											<div className="customize-containers">
												<button
													className="copy-button main-content-button customize assistant-chat-copy-button"
													onClick={() => handleOpenSaveModal(message.content)}
													title="Add This Response To Knowledgebase"
												>
													<FontAwesomeIcon icon={faFloppyDisk} />
												</button>
											</div>
											{enableImageCreation && conversation.length > 0 && (
												<div className="customize-containers">
													<button
														className="copy-button main-content-button customize assistant-chat-copy-button"
														onClick={handleGenerateImage}
														disabled={loading}
													>
														{loading ? (
															<FontAwesomeIcon icon={faSpinner} spin />
														) : (
															<FontAwesomeIcon icon={faImage} />
														)}
													</button>
												</div>
											)}
										</div>
									)}
									{message.images &&
										message.images.map((url, imgIndex) => (
											<div
												key={`${index}-${imgIndex}`}
												className="generated-image Assistant message"
											>
												<img
													src={url}
													alt={`Generated from prompt ${imgIndex + 1}`}
													onClick={() => handleImageClick(url)}
													className="dalle-create"
												/>
											</div>
										))}
								</div>
							))}
							<div ref={conversationEndRef}></div>
						</div>
						<div className="chat-input-box">
							{assistant.createdBy === user.uid && (
								<button
									onClick={() => setIsSharingModalOpen(true)}
									className="send-button"
									style={{ marginRight: "10px" }}
								>
									<FontAwesomeIcon icon={faShareNodes} /> Share
								</button>
							)}
							<textarea
								ref={textareaRef}
								id="prompt"
								value={prompt}
								onChange={handlePromptChange}
								placeholder="Type your message here..."
								rows="1"
								className="chat-textarea"
								onKeyDown={handleKeyDown}
							/>
							<button
								className="send-button"
								onClick={handleSendPrompt}
								disabled={loading || !threadId}
							>
								{loading ? (
									<FontAwesomeIcon icon={faSpinner} spin />
								) : (
									<>
										<FontAwesomeIcon icon={faPaperPlane} /> Chat
									</>
								)}
							</button>
						</div>
						{chatKnowledge && (
							<div className="chat-knowledgeBase">
								{knowledgeBaseFiles.map((file, index) => (
									<p key={index}>{file.filename}</p>
								))}
							</div>
						)}
					</div>
				</>
			) : (
				<div>Loading...</div>
			)}
			{showImageModal && (
				<div className="image-modal" onClick={() => setShowImageModal(false)}>
					<div className="image-modal-content">
						<span
							className="close-modal"
							onClick={() => setShowImageModal(false)}
						>
							<FontAwesomeIcon icon={faTimes} />
						</span>
						<img
							src={selectedImageUrl}
							alt="Generated"
							className="modal-image"
						/>
					</div>
				</div>
			)}
			{showSaveModal && (
				<div className="modal-overlay">
					<div className="modal-content">
						<h3>Save to Knowledge Base</h3>
						<div className="save-options">
							<label>
								<input
									type="radio"
									value="new"
									checked={saveMode === "new"}
									onChange={() => setSaveMode("new")}
								/>
								Create new file
							</label>
							<label>
								<input
									type="radio"
									value="append"
									checked={saveMode === "append"}
									onChange={() => setSaveMode("append")}
								/>
								Append to existing file
							</label>
						</div>
						{saveMode === "new" ? (
							<input
								type="text"
								value={saveFileName}
								onChange={(e) => setSaveFileName(e.target.value)}
								placeholder="Enter file name"
							/>
						) : (
							<select
								value={selectedExistingFile}
								onChange={(e) => setSelectedExistingFile(e.target.value)}
							>
								<option value="">Select a file</option>
								{existingFiles.map((file) => (
									<option key={file.id} value={file.id}>
										{file.name}
									</option>
								))}
							</select>
						)}
						<div className="modal-buttons">
							<button
								onClick={handleSaveToKnowledgeBase}
								className="save-button"
							>
								Save
							</button>
							<button
								onClick={() => setShowSaveModal(false)}
								className="cancel-button"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AssistantChat;
